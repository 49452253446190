import React, { useEffect } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import styled from "styled-components";

import Dashboard from "./pages/dashboard/Dashboard";
import Details from "./pages/details/Details";
import Orders from "./pages/orders/Orders";
import Membership from "./pages/membership/Membership";
import Login from "./pages/loginPage/Login";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserAndData } from "./redux/user/user.actions";
import PasswordSuccesful from "./pages/forgotPassword/passwordSuccesful";
import ResetPage from "./pages/forgotPassword/ResetPage";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword.js";
import NewPassword from "./pages/newPassword/newPassword.js";
import Header from "./components/header/Header";
import Discount from "./pages/discount/Discount";

function App() {
  const dispatch = useDispatch();
  const history = useHistory();

  const products = useSelector((state) => state.shop.products);
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (token && products === null) {
      dispatch(fetchUserAndData());
    }
  }, [dispatch, token, products, history]);

  return (
    <BrowserRouter basename="/">
      <Wrapper>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <Route exact path="/nieuw-wachtwoord">
            <NewPassword />
          </Route>
          <Route exact path="/nieuw-wachtwoord/succes">
            <PasswordSuccesful />
          </Route>
          <Route exact path="/nieuw-wachtwoord/herstel/:id">
            <ResetPage />
          </Route>
          <Route exact path="/discount">
            <Discount />
          </Route>
          <Route exact path="/wachtwoord-vergeten">
            <ForgotPassword />
          </Route>
          <Route exact path="/wachtwoord-vergeten/succes">
            <PasswordSuccesful />
          </Route>
          <Route exact path="/wachtwoord-vergeten/herstel/:id">
            <ResetPage />
          </Route>
          <Route exact path="/dashboard">
            <Header />
            {user.token ? <Dashboard /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/details">
            <Header />
            {user.token ? <Details /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/orders">
            <Header />
            {user.token ? <Orders /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/membership">
            <Header />
            {user.token ? <Membership /> : <Redirect to="/" />}
          </Route>
        </Switch>
      </Wrapper>
    </BrowserRouter>
  );
}
export default App;

const Wrapper = styled.div`
  padding-bottom: 4rem;
  background-color: #f5f5f5;
  min-height: 100vh;
`;
