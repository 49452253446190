import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { setActiveTabSubscription } from "../../../../../redux/subscriptions/subscriptions.actions";
import Gif from "../../../../../assets/post-dog.gif";

const BevestigingFrequentie = () => {
  const dispatch = useDispatch();

  const subscription = useSelector(
    (state) => state.subscriptions.activeSubscription
  );

  const Frequentie = (subscription) => {
    if (subscription.billingInterval === 1) {
      return "maandelijks";
    } else if (subscription.billingInterval === 2) {
      return "elke 2 maanden";
    } else if (subscription.billingInterval === 3) {
      return "elke 3 maanden";
    } else if (subscription.billingInterval === 4) {
      return "elke 4 maanden";
    }
  };

  return (
    <Wrapper>
      <div className="mb-3">
        <span className="h3">Frequentie aangepast!</span>
      </div>
      <img src={Gif} alt="dog-waving" className="w-100 mb-4" />
      <div>
        {subscription.billing_interval === "1" ? (
          <span>
            Vanaf nu krijg je {Frequentie(subscription)} een nieuwe boxershort.
            <br />
            <br />
            Je eerstvolgende afschrijving is aan het{" "}
            <b>
              einde van {moment(subscription.nextPaymentDate).format("MMMM")}
            </b>{" "}
            en deze boxershort ontvang je in het{" "}
            <b>
              begin van{" "}
              {moment(subscription.nextPaymentDate)
                .add(1, "months")
                .format("MMMM")}
            </b>
            .
          </span>
        ) : (
          <span>
            Je krijgt aan het{" "}
            <b>
              einde van {moment(subscription.nextPaymentDate).format("MMMM")}
            </b>{" "}
            je laatste afschrijving. Deze boxershort ontvang je in het{" "}
            <b>
              begin van{" "}
              {moment(subscription.nextPaymentDate)
                .add(1, "months")
                .format("MMMM")}
            </b>
            .
            <br />
            <br />
            Daarna ontvang je {Frequentie(subscription)} een nieuwe boxershort.
          </span>
        )}
      </div>
      <div className="mt-4">
        <button
          className="btn btn-primary"
          onClick={() => dispatch(setActiveTabSubscription(null))}
        >
          Sluiten
        </button>
      </div>
    </Wrapper>
  );
};

export default BevestigingFrequentie;

const Wrapper = styled.div`
  background-color: white;
  padding: 3rem;
  height: 100%;
  -webkit-box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
`;
