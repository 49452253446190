import style from "./LoginPage.module.css";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { ReactComponent as Logo } from "../../assets/Udzwart.svg";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sendGif from "../../assets/sendgif.gif";
import React from "react";
import { useDispatch } from "react-redux";
import { CancelNewPw } from "../../redux/user/user.actions";

const PasswordSuccesful = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleBack = () => {
    dispatch(CancelNewPw());

    history.push("/");
  };

  return (
    <div className={style.loginWrapper}>
      <div className={style.menubar}>
        <div className="container">
          <Logo />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-6 ml-auto mr-auto text-center bg-white pt-3 pb-5">
            <img src={sendGif} style={{ maxWidth: "100px" }} alt="send_gif" />
            <h2 className={style.title}>Herstel email is verzonden!</h2>
            <p className="p pl-5 pr-5 pb-3">
              Als je email bij ons bekend is, ontvang je binnen een minuut een
              email om je wachtwoord te herstellen.
            </p>
            <Button
              className="pr-3 pl-3 pt-2 pb-2"
              onClick={() => handleBack()}
            >
              Terug
            </Button>
          </div>
        </div>
      </div>
      <StyledContainer />
    </div>
  );
};

export default PasswordSuccesful;

const StyledContainer = styled(ToastContainer).attrs()`
  .Toastify__toast--error {
    background-color: #dc3545;
  }
  .Toastify__toast--success {
    background-color: #007bff;
  }
`;
