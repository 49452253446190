import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useSelector, useDispatch } from "react-redux";
import { postUser } from "../../../redux/user/user.actions";
import styled from "styled-components";

const DetailsPerson = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.details);

  const [Person, setPerson] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
  });

  const HandlePerson = (e) => {
    e.preventDefault();

    const body = {
      first_name: Person.first_name,
      last_name: Person.last_name,
    };

    dispatch(postUser(body));
  };

  return (
    <div className="w-100 bg-white">
      <Card>
        <h4>Persoonlijke gegevens</h4>
        <p>Adresgegevens kan je vinden bij het membership.</p>
        <Form onSubmit={HandlePerson}>
          <div className="row mt-4">
            <div className="col-12 col-md-6">*Voornaam</div>
            <div className="col-12 col-md-6 ml-auto">
              <Form.Control
                placeholder="Voornaam"
                type="text"
                value={Person?.first_name || null}
                onChange={(e) =>
                  setPerson({ ...Person, first_name: e.target.value })
                }
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 col-md-6">*Achternaam</div>
            <div className="col-12 col-md-6 ml-auto">
              <Form.Control
                type="text"
                value={Person?.last_name || null}
                onChange={(e) =>
                  setPerson({ ...Person, last_name: e.target.value })
                }
                placeholder="Achternaam"
              />
            </div>
          </div>
          <div className="mt-3 text-right">
            <Button className="btn btn-primary" type="submit">
              Opslaan
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default DetailsPerson;

const Card = styled.div`
  padding: 2rem;
  border: 0.0625rem solid #e8eef0;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
`;
