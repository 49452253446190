import React from "react";
import { useState } from "react";
import Calendar from "./Form/Form";
import Quit from "../Stoppen/Quit";
import Discount from "./discount";

const Pauze = ({ setItem, activeItem }) => {
  const [quitTab, setQuit] = useState(false);
  const [finance, setFinance] = useState(false);

  return (
    <>
      {finance ? (
        <Discount setQuit={setQuit} setFinance={setFinance} />
      ) : quitTab ? (
        <Quit setQuit={setQuit} setItem={setItem} activeItem={activeItem} />
      ) : (
        <Calendar
          setQuit={setQuit}
          activeItem={activeItem}
          setFinance={setFinance}
        />
      )}
    </>
  );
};

export default Pauze;
