import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Accordion from "./Details/Accordion";

const Details = () => {
  const dispatch = useDispatch();

  const activeSubscription = useSelector(
    (state) => state.subscriptions.activeSubscription
  );

  return (
    <Inside className="mt-4">
      <div className="col-12">
        <Name className="h2">
          {activeSubscription?.shipping?.first_name || null}
          {"  "}
          {activeSubscription?.shipping?.last_name || null}
        </Name>
      </div>
      <div className="col-12">
        {activeSubscription ? (
          <Accordion activeSubscription={activeSubscription} />
        ) : null}
      </div>
    </Inside>
  );
};

export default Details;

const Inside = styled.div`
  padding: 3rem 2rem 3rem;
  background-color: white;
  border-radius: 10px;
  max-width: 1100px;
  margin: 0 auto 0;
`;

const Name = styled.span`
  font-weight: 700;
`;
