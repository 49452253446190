import axios from "axios";
import { fetchUrl } from "../../fetchurl";

export const postNewSubscription = async (token, body) => {
  return axios
    .post(fetchUrl + "/user/subscriptions", body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    })
    .catch((err) => console.log(err));
};
