import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useSelector, useDispatch } from "react-redux";
import { postUser } from "../../../redux/user/user.actions";
import React from "react";
import styled from "styled-components";

const DetailsAdress = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.details);

  const [Adress, setAdress] = useState({
    email: user.email,
    phone: user.phone,
  });

  const handleAdress = (e) => {
    e.preventDefault();

    const body = {
      oldEmail: user.email,
      phone: Adress.phone,
      email: Adress.email,
    };

    dispatch(postUser(body));
  };

  return (
    <Card>
      <h4>Contact gegevens</h4>
      <br/>
      <Form onSubmit={handleAdress}>
        <div className="row mt-4">
          <div className="col-12 col-md-4">Telefoon*</div>
          <div className="col-12 col-md-8">
            <Form.Control
              placeholder="Telefoonnummer"
              type="text"
              value={Adress.phone || null}
              onChange={(e) => setAdress({ ...Adress, phone: e.target.value })}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-md-4">Email*</div>
          <div className="col-12 col-md-8">
            <Form.Control
              type="text"
              value={Adress?.email || null}
              onChange={(e) => setAdress({ ...Adress, email: e.target.value })}
              placeholder="email"
            />
          </div>
        </div>
        <div className="mt-3 text-right">
          <Button className="btn btn-primary" type="submit">
            Opslaan
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default DetailsAdress;

const Card = styled.div`
  padding: 2rem;
  border: 0.0625rem solid #e8eef0;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
`;
