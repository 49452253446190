import WijdeBoxer from "../../../../assets/Wijd.png";
import styled from "styled-components";
import React, { useState } from "react";
import { setNewSubscription } from "../../../../redux/subscriptions/subscriptions.actions";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const Size = ({ setPage }) => {
  const dispatch = useDispatch();

  const [chosenSize, setSize] = useState("S");

  const handleClick = () => {
    if (!chosenSize) {
      toast.error("Kies een maat!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      const body = {
        size: chosenSize,
      };

      dispatch(setNewSubscription(body));
      setPage("Name");
    }
  };

  return (
    <Card>
      <Inner>
        <div>
          <span className="text-white h2 w-100 font-weight-bold mb-2">
            Wijde boxershort
          </span>
          <br />
          <span className=" text-white">Gemaakt van 100% katoen, €12.99.</span>
        </div>
        <Boxer src={WijdeBoxer} alt="wijde boxershort" />
        <div className="mt-4">
          <Label>
            <Select
              className="form-select"
              onChange={(e) => setSize(e.target.value)}
            >
              <option>Maat</option>
              <option value="S">S</option>
              <option value="M">M</option>
              <option value="L">L</option>
              <option value="XL">XL</option>
              <option value="XXL">XXL</option>
            </Select>
          </Label>
          <Button className="btn" onClick={() => handleClick()}>
            Kies
          </Button>
        </div>
      </Inner>
    </Card>
  );
};

export default Size;

const Card = styled.div`
  padding: 7rem 3rem;
  margin-top: 2rem;
  background-color: #204368;
  border-radius: 10px;
`;

const Boxer = styled.img`
  max-width: 300px;
`;

const Inner = styled.div`
  margin: 0 auto;
  text-align: center;
`;

const Select = styled.select`
  padding: 0.6rem 4rem;
  color: #333;
  border-radius: 4px;
  font-weight: 600;
  border-radius: 4px;
  border: none;
  outline: none;
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
`;

const Button = styled.button`
  border: white solid 2px;
  margin-left: 10px;
  border-radius: 30px;
  padding: 0.47rem 2rem;
  vertical-align: 0px;
  color: white;

  :hover {
    border: solid 2px lightgray;
    color: lightgray;
  }
`;

const Label = styled.label`
  position: relative;

  :after {
    content: ">";
    font: 20px "Poppins", monospace;
    color: #333;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    right: 8px;
    top: 8px;
    position: absolute;
    pointer-events: none;
  }

  :before {
    content: "";
    right: 6px;
    top: 0px;
    background: #f8f8f8;
    position: absolute;
    pointer-events: none;
  }
`;
