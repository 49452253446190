import React from "react";
import RefreshCard from "./refreshCard/RefreshCard";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Person from "./personCard/Person";
import Orders from "./ordersCard/Orders";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import SpinnerComponent from "../../components/Spinner/SpinnerComponent";

const Dashboard = () => {
  const user = useSelector((state) => state.user);

  if (!user.details) {
    return <SpinnerComponent />;
  }

  return (
    <>
      {user.token ? (
        <Wrapper>
          <StyledContainer />
          <InsideWrapper>
            <div className="container">
              <div className="row w-100 mr-0 ml-0">
                <div className="col-lg-8">
                  <RefreshCard />
                  <Orders />
                </div>
                <div className="col-lg-4">
                  <Person />
                </div>
              </div>
            </div>
          </InsideWrapper>
        </Wrapper>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default Dashboard;

const Wrapper = styled.div`
  background-color: white;
  margin: 0 auto;
`;

const StyledContainer = styled(ToastContainer).attrs()`
  .Toastify__toast--error {
    background-color: #dc3545;
  }
  .Toastify__toast--success {
    background-color: #007bff;
  }
`;

const InsideWrapper = styled.div`
  background-color: #f5f5f5;
  min-height: 84vh;
`;
