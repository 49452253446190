import { useSelector } from "react-redux";
import styled from "styled-components";
import React from "react";

export const DetailsImage = (order, variations) => {


  const orderVariation = variations.products.find(
    (variation) => order?.variant_title.toUpperCase() === variation?.title.toUpperCase());
  
   const orderVariation2 = variations.products.find(
     (variation) => order?.title.toUpperCase() === variation?.title.toUpperCase());
  
     const orderVariation3 = variations.products.find(
       (variation) => order?.product_id === variation?.id);
  
  
    const orderVariation4 = variations.products.find((element) => {
      return element.variants.map((state) => {
          if(state.sku === order?.sku){
            return state
          }
        });
    });
  

    return (
       <>
        <img key={order.id} src={orderVariation?.image.src || orderVariation2?.image.src || orderVariation3?.image.src || orderVariation4?.image.src} alt="product" />
      </>
    );
  };

