import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { BoxArrowRight, ChevronLeft } from "react-bootstrap-icons/";
import Logo from "../../assets/udlogo-wit.svg";
import { useSelector, useDispatch } from "react-redux";
import { removeUser, setActivePage } from "../../redux/user/user.actions";
import ScrollContainer from "react-indiana-drag-scroll";
import styled from "styled-components";
import {
  setActiveTabSubscription,
  setActiveSubscription,
} from "../../redux/subscriptions/subscriptions.actions";

function Header() {
  const user = useSelector((state) => state.user.details);

  const active = useSelector((state) => state.user.activePage);

  const activeSubscription = useSelector(
    (state) => state.subscriptions.activeSubscription
  );

  const activeTab = useSelector((state) => state.subscriptions.activeTab);

  const dispatch = useDispatch();

  const handleMobileBack = () => {
    if (activeTab) {
      dispatch(setActiveTabSubscription(null));
    } else {
      dispatch(setActiveSubscription(null));
    }
  };

  var width = window.innerWidth;

  return (
    <>
      {(width <= 768 && !activeSubscription) || width >= 768 ? (
        <HeaderLine>
          <Menubar>
            <div className="container pt-1">
              <object
                data={Logo}
                type="image/svg+xml"
                aria-label="Logo-UD-white"
                width="160px"
              ></object>
            </div>
          </Menubar>
          <div className="container">
            <div className="row mr-0 ml-0">
              <div className="col">
                <Greeting>
                  <h3>{user ? <> Hallo {user.first_name}!</> : null}</h3>
                </Greeting>
              </div>
            </div>
            <div className="row mr-0 ml-0">
              <div className="col">
                <ScrollContainerStyled horizontal="true" vertical="false">
                  <Link>
                    <NavLink
                      exact
                      onClick={() => dispatch(setActivePage("account"))}
                      style={
                        active === "account"
                          ? { borderBottom: "solid 3px #007bff" }
                          : null
                      }
                      to="/dashboard"
                    >
                      Overzicht
                    </NavLink>
                    <NavLink
                      style={
                        active === "membership"
                          ? { borderBottom: "solid 3px #007bff" }
                          : null
                      }
                      onClick={() => dispatch(setActivePage("membership"))}
                      to="/membership"
                    >
                      Membership
                    </NavLink>

                    <NavLink
                      onClick={() => dispatch(setActivePage("orders"))}
                      style={
                        active === "orders"
                          ? { borderBottom: "solid 3px #007bff" }
                          : null
                      }
                      to="/orders"
                    >
                      Bestellingen
                    </NavLink>
                    <NavLink
                      style={
                        active === "details"
                          ? { borderBottom: "solid 3px #007bff" }
                          : null
                      }
                      onClick={() => dispatch(setActivePage("details"))}
                      to="/details"
                    >
                      Gegevens
                    </NavLink>
                    <LogOut onClick={() => dispatch(removeUser())}>
                      Uitloggen <BoxArrowRight className="d-none d-lg-inline" />
                    </LogOut>
                  </Link>
                </ScrollContainerStyled>
              </div>
            </div>
          </div>
        </HeaderLine>
      ) : (
        <ReasonBar>
          <StyledChevronLeft
            onClick={() => handleMobileBack(null)}
            type="button"
            color="white"
          />
          <span>Terug</span>
        </ReasonBar>
      )}
    </>
  );
}

export default Header;

const ReasonBar = styled.div`
  padding: 0.4rem 0.4rem;
  width: 100%;
  position: relative;
  text-align: center;
  background: black;

  span {
    color: white;
    text-align: center;
    font-weight: 700;
  }
`;
const StyledChevronLeft = styled(ChevronLeft)`
  position: absolute;
  left: 10px;
  top: 12.5px;
`;

const HeaderLine = styled.div`
  width: 100%;
  background-color: white;
  border-bottom: 0.0625rem solid #e8eef0;
`;

const Menubar = styled.div`
  width: 100%;
  text-align: center;
  background-color: black;
  padding: 0.5rem 0 0.2rem;

  img {
    max-width: 150px;
    fill: white;
    padding: 0.25rem 0;
  }
`;

const Greeting = styled.div`
  padding: 2rem 0 0 0;
`;

const ScrollContainerStyled = styled(ScrollContainer)``;

const Link = styled.div`
  margin-top: 10px;
  display: flex;

  a {
    margin: 10px 30px 0px 20px;
    color: black;
    text-decoration: none;
    padding-bottom: 15px;
  }

  a:first-child {
    margin-left: 0px;
  }

  a:last-child {
    margin-left: auto;
  }
`;

const LogOut = styled.a`
  border: 0px;
  text-decoration: none;
  color: #a5abb3 !important;
  cursor: pointer;
  padding-left: 20px;
`;
