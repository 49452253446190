import axios from "axios";
import { fetchUrl } from "../../fetchurl";

export const changeMethod = async (customerId, body, token) => {
  const result = await sendMethod(customerId, body, token);

  const data = result.data;

  return data;
};

const sendMethod = async (customerId, body, token) => {
  return axios
    .post(fetchUrl + "/user/change-method/" + customerId, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    })
    .catch((err) => console.log(err));
};
