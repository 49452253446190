import React from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import styled from "styled-components";
import { createSubscriptionNote } from "../../../../../../../../redux/user/user.actions";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import {
  postSubscriptions,
  setActiveTabSubscription,
} from "../../../../../../../../redux/subscriptions/subscriptions.actions";

const Form = () => {
  const dispatch = useDispatch();

  const subscription = useSelector(
    (state) => state.subscriptions.activeSubscription
  );

  const subscriptions = useSelector((state) => state.subscriptions);

  const [frequency, setFrequency] = useState(0);

  const FrequentieFinder = (subscription) => {
    if (subscription.billingInterval === 1) {
      return "maandelijks";
    } else if (subscription.billingInterval === 2) {
      return "elke 2 maanden";
    } else if (subscription.billingInterval === 3) {
      return "elke 3 maanden";
    } else if (subscription.billingInterval === 4) {
      return "elke 4 maanden";
    }
  };

  const currentMonth = () => {
    return moment(new Date()).format("YYYY-MM-26 0:0:0");
  };

  const handleSubmit = async () => {
    if (frequency === 0) {
      toast.warn("Je hebt geen frequentie gekozen", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      const body = {
        billingInterval: parseFloat(frequency),
        nextPaymentDate: currentMonth(),
      };

      await dispatch(postSubscriptions(subscription._id, body));

      dispatch(setActiveTabSubscription("Frequency"));
    }
  };

  return (
    <div className="p-3">
      <h3>Frequentie aanpassen</h3>
      <span>
        Stel hieronder gemakkelijk je frequentie in. Deze gaat in na je volgende
        levering. Je huidige frequentie is {FrequentieFinder(subscription)}.
      </span>
      <div className="mt-4">
        <form className="row  ml-auto mr-auto mb-3">
          <di className="col-6 pt-2 pb-2 pl-0">
            <Label
              className={
                frequency === "1"
                  ? "border-dark font-weight-bold border-4 col-12"
                  : "col-12"
              }
            >
              Maandelijks
              <input
                type="radio"
                value="1"
                name="adjust"
                checked={frequency === "1"}
                onChange={(e) => setFrequency(e.target.value)}
              />
            </Label>
          </di>
          <div className="col-6 pt-2 pb-2 pl-0">
            <Label
              className={
                frequency === "2"
                  ? "border-dark font-weight-bold border-4 col-12"
                  : "col-12"
              }
            >
              Elke twee maanden
              <input
                type="radio"
                value="2"
                name="adjust"
                checked={frequency === "2"}
                onChange={(e) => setFrequency(e.target.value)}
              />
            </Label>
          </div>
          <div className="col-6 pt-2 pb-2 pl-0">
            <Label
              className={
                frequency === "3"
                  ? "border-dark font-weight-bold border-4 col-12"
                  : "col-12"
              }
            >
              Elke drie maanden
              <input
                type="radio"
                value="3"
                name="adjust"
                checked={frequency === "3"}
                onChange={(e) => setFrequency(e.target.value)}
              />
            </Label>
          </div>
          <div className="col-6 pt-2 pb-2 pl-0">
            <Label
              className={
                frequency === "4"
                  ? "border-dark border-4 font-weight-bold col-12"
                  : "col-12"
              }
            >
              Elke vier maanden
              <input
                type="radio"
                value="4"
                name="adjust"
                checked={frequency === "4"}
                onChange={(e) => setFrequency(e.target.value)}
              />
            </Label>
          </div>
        </form>
      </div>
      <div className="text-center">
        {subscriptions.isLoading === false ? (
          <Button
            className="btn mt-4 btn-primary btn-md"
            onClick={() => handleSubmit()}
          >
            Verander frequentie
          </Button>
        ) : (
          <Button className="btn btn-primary mt-4">
            <StyledSpinner animation="border" variant="white" />
          </Button>
        )}
      </div>
    </div>
  );
};

export default Form;

const Label = styled.label`
  text-align: left;
  padding: 1.25rem 1rem 1rem 1rem;
  border: 2px solid #d8e0e3;
  width: 100%;
  height: 100%;
  background-color: white;

  & :nth-child(2) {
    border-bottom: 0px;
  }

  & > input[type="radio"] {
    visibility: hidden;
    height: 0px;
    width: 0px;
  }

  & > div > div > input[type="radio"] {
    visibility: hidden;
    height: 0px;
    width: 0px;
  }

  & > div > div > span {
    margin-left: -5px;
  }
`;

const Button = styled.button``;

const StyledSpinner = styled(Spinner)`
  width: 20px;
  height: 20px;
`;
