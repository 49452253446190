import axios from "axios";
import { fetchUrl } from "../../fetchurl";

export const getVariations = async () => {
  const result = await fetchVariations();

  const variations = result.data;
  return variations;
};

const fetchVariations = async () => {
  return axios
    .get(fetchUrl + "/shop/all-variations", {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch((err) => {
      console.log(err);
    });
};
