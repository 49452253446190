import React, { useState } from "react";
import Size from "./New/Size";
import Name from "./New/Name";
import Address from "./New/Address";

const New = ({ setMembership }) => {
  const [Page, setPage] = useState("Size");

  return (
    <div className="mt-4">
      <span className="h2">Nieuw Membership</span>
      <div>
        <span
          className="mt-2 font-weight-bold"
          onClick={() => setMembership(false)}
          type="button"
        >
          Membership
        </span>
        <span> / </span>
        <span className="text-secondary font-weight-bold">
          Nieuw membership
        </span>
      </div>
      {Page === "Size" ? (
        <Size setPage={setPage} />
      ) : Page === "Name" ? (
        <Name setPage={setPage} />
      ) : Page === "Address" ? (
        <Address setPage={setPage} />
      ) : null}
    </div>
  );
};

export default New;
