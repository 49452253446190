import { userActionTypes } from "./user.types.js";

const INITIAL_STATE = {
  token: null,
  details: null,
  loginData: null,
  changePayment: null,
  isLoading: false,
  activePage: "account",
  newPW: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userActionTypes.FETCH_TOKEN_REQUEST:
      return {
        ...state,
        isLoading: true,
        loginData: action.payload,
      };

    case userActionTypes.NEW_PW_CANCEL:
      return {
        ...state,
        isLoading: false,
        newPW: false,
      };

    case userActionTypes.FETCH_TOKEN_SUCCES:
      return {
        ...state,
        token: action.payload,
        isLoading: false,
      };

    case userActionTypes.FETCH_TOKEN_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false,
      };

    case userActionTypes.RESET_PW_SUCCES:
      return {
        ...state,
        loginData: {
          username: state.loginData.username,
          password: action.payload,
        },
        newPW: false,
      };

    case userActionTypes.FETCH_TOKEN_NEW_PW:
      return {
        ...state,
        isLoading: false,
        newPW: true,
      };

    case userActionTypes.FETCH_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case userActionTypes.FETCH_USER_SUCCES:
      return {
        ...state,
        details: action.payload,
        isLoading: false,
      };

    case userActionTypes.FETCH_USER_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false,
      };

    case userActionTypes.POST_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case userActionTypes.POST_USER_SUCCES:
      return {
        ...state,
        isLoading: false,
        details: action.payload,
      };

    case userActionTypes.POST_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case userActionTypes.CHANGE_METHOD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case userActionTypes.CHANGE_METHOD_SUCCES:
      return {
        ...state,
        isLoading: false,
      };

    case userActionTypes.CHANGE_METHOD_FAILLURE:
      return {
        ...state,
        isLoading: false,
      };

    case userActionTypes.SET_ACTIVE_PAGE:
      return {
        ...state,
        activePage: action.payload,
      };

    case userActionTypes.RESTORE_CART_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case userActionTypes.RESTORE_CART_SUCCES:
      return {
        ...state,
        isLoading: false,
        checkoutCart: action.payload,
      };

    case userActionTypes.RESTORE_CART_FAILURE:
      return {
        ...state,
        isLoading: false,
        checkoutCart: 0,
      };

    case userActionTypes.REACTIVATE_WITH_DISCOUNT:
      return {
        ...state,
        isLoading: false,
        token: action.payload,
        error: false,
      };

    case userActionTypes.REACTIVATE_WITH_DISCOUNT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case userActionTypes.REACTIVATE_NO_MANDATE:
      return {
        ...state,
        details: action.payload,
      };

    case userActionTypes.FETCH_MANDATE_SUCCESS:
      return {
        ...state,
        mandate: true,
        details: action.payload,
      };

    case userActionTypes.FETCH_MANDATE_FAILURE:
      return {
        ...state,
        details: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
