import DetailsPerson from "./Person/Person";
import DetailsAdress from "./Adress/Adress";
import React from "react";
import DetailsAccount from "./Account/Account";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Payment from "./Payment/payment";

const Details = () => {
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.user.token);

  return (
    <>
      {token ? (
        <Wrapper>
          <div className="container">
            {user.isLoading === true ? (
              <Loading>
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </Loading>
            ) : null}
            <div className="row w-100 mr-0 ml-0">
              <Card className="col-lg-6">
                <DetailsPerson user={user} />
                <DetailsAccount />
              </Card>
              <Card className="col-lg-6">
                <DetailsAdress />
                <Payment />
              </Card>
            </div>
          </div>
          <StyledContainer />
        </Wrapper>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default Details;

const Wrapper = styled.div`
  background-color: #f5f5f5;
  padding-bottom: 4rem;
  min-height: 84vh;
`;

const Loading = styled.div`
  position: absolute;
  left: 49%;
  top: 42%;
  z-index: 99;
`;

const Card = styled.div`
  margin-top: 2rem;
`;

const StyledContainer = styled(ToastContainer).attrs()`
  .Toastify__toast--error {
    background-color: #dc3545;
  }
  .Toastify__toast--success {
    background-color: #007bff;
  }
`;
