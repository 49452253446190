import axios from "axios";
import { fetchUrl } from "../../fetchurl";

export const getOrders = async (token) => {
  const result = await fetchOrders(token);

  const data = result?.data || [];

  return data;
};

const fetchOrders = async (token) => {
  return axios
    .get(fetchUrl + "/user/orders", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    })
    .catch((err) => console.log(err));
};
