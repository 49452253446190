import "moment/locale/nl";
import React from "react";
import moment from "moment";
import { ArrowRight } from "react-bootstrap-icons";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { setDetailOrder } from "../../../redux/orders/orders.actions";
import { useHistory } from "react-router-dom";
import { OrderStatus } from "../../../helper/orders/orderStatus/orderStatus";
import { OrderImage } from "../../../helper/orders/orderImage/OrderImage";
import { setActivePage } from "../../../redux/user/user.actions";

function Order({ order }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = (order) => {
    dispatch(setDetailOrder(order));
    dispatch(setActivePage("orders"));
    history.push("/orders");
  };

  const variations = useSelector((state) => state.shop.variations);

  return (
    <div
      className="p-3"
      key={order.id}
      role="button"
      onClick={() => handleClick(order)}
    >
      <div className="row">
        <Image className="col-5 p-0 col-md-2">
          {OrderImage(order, variations)}
        </Image>
        <div className="col-7 p-3 col-md-10">
          <div className="row">
            <div className="col-12 col-md-3">
              <span className="d-none d-md-block">#{order.name}</span>
              <span style={{ textTransform: "capitalize" }}>
                {moment(order.processed_at).format("DD") < 23
                  ? moment(order.processed_at).local("nl").format("MMMM")
                  : moment(order.processed_at)
                      .local("nl")
                      .add(1, "months")
                      .format("MMMM")}
              </span>
            </div>
            <div className="col-6 col-md-3">
              <span className="d-block">Totaal</span>
              <span>€{order.total_price}</span>
            </div>
            <div className="col-6 col-md-3">
              <span className="d-block">Status</span>
              <Status className="bg-primary text-white">
                {OrderStatus(order)}
              </Status>
            </div>
            <div className="col-2 d-none d-md-block">
              <span>
                <StyledArrowRight size="25" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Order;

const Status = styled.span`
  border-radius: 20px;
  padding: 0.3rem 0.8rem;
  font-size: 12px;
`;

const StyledArrowRight = styled(ArrowRight)`
  color: black;
  margin: 10px 0 0 40px;
  float: right;
  cursor: pointer;
`;

const Image = styled.div`
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
