import Order from "./Order";
import React from "react";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OrderDetails from "./OrderDetails";

function Orders() {
  return (
    <Wrapper>
      <div className="container">
        <OrdersWrap className="row ml-3 mr-3 bg-white">
          <Title className="col-12">
            <h4>Eerdere bestellingen</h4>
          </Title>
          <Order className="col-12" />
        </OrdersWrap>
      </div>
      <OrderDetails />
      <StyledContainer />
    </Wrapper>
  );
}

export default Orders;

const StyledContainer = styled(ToastContainer).attrs()`
  .Toastify__toast--error {
    background-color: #dc3545;
  }
  .Toastify__toast--success {
    background-color: #007bff;
  }
`;

const Wrapper = styled.div`
  background-color: #f5f5f5;
  padding-bottom: 4rem;
  min-height: 78vh;
  padding-top: 2rem;
`;

const Title = styled.div`
  border: 0.0625rem solid #e8eef0;
  padding: 2.5rem 2rem 2rem;
  background-color: white;
`;

const OrdersWrap = styled.div`
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
`;
