import React, { useState } from "react";
import style from "./LoginPage.module.css";
import { useHistory, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { ReactComponent as Logo } from "../../assets/Udzwart.svg";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { sendPasswordReset } from "../../components/queries/sendPasswordReset";
import { succesPWReset } from "../../redux/user/user.actions";
import { useDispatch } from "react-redux";

const ResetPage = () => {
  const token = useParams();
  const dispatch = useDispatch();

  const [wachtwoord, setWachtwoord] = useState({
    wachtwoord1: "",
    wachtwoord2: "",
  });
  const history = useHistory();

  const sendPassword = async (e) => {
    e.preventDefault();

    if (wachtwoord.wachtwoord1 !== wachtwoord.wachtwoord2) {
      toast.error("Je wachtwoord komt niet overeen!", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (wachtwoord.length < 6) {
      toast.error("Je wachtwoord is te kort!", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const body = { password: wachtwoord.wachtwoord1, token: token.id };
      const result = await sendPasswordReset(body);

      if (result.status === 200) {
        dispatch(succesPWReset(wachtwoord.wachtwoord1));

        history.push("/");

        toast.success("Je wachtwoord is succesvol aangepast!", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Het wachtwoord aanpassen is mislukt!", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  return (
    <div className={style.loginWrapper}>
      <div className={style.menubar}>
        <div
          className="container"
          onClick={() => history.push("/")}
          role="button"
        >
          <Logo />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-10 col-md-5 bg-white p-5 ml-auto mr-auto">
            <p className="h3 text-center">Kies je nieuwe wachtwoord</p>
            <form className="mt-4" onSubmit={sendPassword}>
              <span>Nieuw wachtwoord</span>
              <input
                className={style.input}
                type="password"
                onChange={(e) =>
                  setWachtwoord({ ...wachtwoord, wachtwoord1: e.target.value })
                }
                autoComplete="password"
              />
              <span>Herhaal nieuw wachtwoord</span>
              <input
                className={style.input}
                type="password"
                onChange={(e) =>
                  setWachtwoord({ ...wachtwoord, wachtwoord2: e.target.value })
                }
                autoComplete="password"
              />
              <br />
              <div className="text-center">
                <Button className="mt-3 pt-2 pb-2 pr-4 pl-4 " type="submit">
                  Doorgaan
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <StyledContainer />
    </div>
  );
};

export default ResetPage;

const StyledContainer = styled(ToastContainer).attrs()`
  .Toastify__toast--error {
    background-color: #dc3545;
  }
  .Toastify__toast--success {
    background-color: #007bff;
  }
`;
