import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import WijdeBoxer from "../../../../../assets/Wijd.png";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { postSubscriptions } from "../../../../../redux/subscriptions/subscriptions.actions";
import React from "react";

const SizeCard = (activeSubscription) => {
  const dispatch = useDispatch();

  const subscriptions = useSelector((state) => state.subscriptions);

  const [size, setSize] = useState(activeSubscription.subscription.size);

  const handleSize = () => {
    const body = {
      size: size,
    };

    dispatch(postSubscriptions(activeSubscription.subscription._id, body));
  };
  return (
    <Card>
      <div className="row">
        <div className="col-12 col-md-6">
          <h3>Maat wijzigen</h3>
          <br />
          <p>Je nieuwe maat wordt gebruikt bij je eerst volgende levering.</p>
          <Select
            defaultValue={activeSubscription.subscription.size}
            onChange={(e) => setSize(e.target.value)}
          >
            <option value="S">S</option>
            <option value="M">M</option>
            <option value="L">L</option>
            <option value="XL">XL</option>
            <option value="XXL">XXL</option>
          </Select>
          <div>
            {subscriptions.isLoading === false ? (
              <Button
                className="btn btn-primary mt-4 checkout-button"
                onClick={() => handleSize()}
              >
                Opslaan
              </Button>
            ) : (
              <Button className="btn btn-primary mt-4">
                <StyledSpinner animation="border" variant="white" />
              </Button>
            )}
          </div>
        </div>
        <div className="col-12 col-md-6 text-center">
          <img alt="boxer-wide" src={WijdeBoxer} className="w-75" />
        </div>
      </div>
    </Card>
  );
};

export default SizeCard;

const Card = styled.div`
  background: white;
  width: 100%;
  border: 4px;
  padding: 3rem 2rem;
  -webkit-box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
  h3 {
    font-weight: 700;
  }
`;

const Select = styled.select`
  position: relative;
  min-width: 200px;
  min-height: 30px;
`;

const StyledSpinner = styled(Spinner)`
  width: 20px;
  height: 20px;
`;
