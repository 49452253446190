import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { discountActivateSubscription } from "../../redux/user/user.actions";
import styled from "styled-components";
import Logo from "../../assets/Udzwart.svg";
import SpinnerComponent from "../../components/Spinner/SpinnerComponent";
import Gif from "../../assets/excited-dog.gif";
import { userActionTypes } from "../../redux/user/user.types";

const Discount = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const user = useSelector((state) => state.user.details);
  const isLoading = useSelector((state) => state.user.isLoading);
  const discountCode = new URLSearchParams(search).get("discount");

  if (isLoading) {
    return <SpinnerComponent />;
  }

  const handleActivate = () => {
    if (token) {
      const discountBody = {
        token: token,
        discount: discountCode,
      };

      dispatch(discountActivateSubscription(discountBody));

      history.push("/");
    } else {
      console.log("error");
    }
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <LogoWrapper>
        <div className="container">
          <span>
            <img src={Logo} alt="Logo-UD-white" max-width="175px"></img>
          </span>
        </div>
      </LogoWrapper>
      <Wrapper className="row">
        <>
          <div></div>
          <div className="bg-white p-4 text-center col-lg-4 offset-lg-4 col-sm-6 offset-sm-3 col-10 offset-1">
            <h4>Heractiveer jouw Membership</h4>
            {discountCode === "50OFF" ? (
              <>
                <img src={Gif} alt="dog-march" className="w-100 mb-4 mt-4 " />
                <p>
                  Ontvang je volgende boxershort voor <b>slechts €6.99</b> bij
                  je volgende levering!
                </p>
              </>
            ) : null}
            <div
              className="btn btn-primary mt-3"
              onClick={() => handleActivate()}
            >
              Heractiveren
            </div>
          </div>
        </>

        <StyledContainer />
      </Wrapper>
    </div>
  );
};

export default Discount;

const Wrapper = styled.div`
  background: #f5f5f5;
`;

const LogoWrapper = styled.div`
  background-color: white;
  text-align: center;
  padding: 0.75rem 0 0.5rem;
  margin-bottom: 5rem;
`;

const StyledContainer = styled(ToastContainer).attrs()`
  .Toastify__toast--error {
    background-color: #dc3545;
  }
  .Toastify__toast--success {
    background-color: #007bff;
  }
`;
