import { useSelector, useDispatch } from "react-redux";
import BoxershortFinder from "../../helper/BoxershortFinder";
import React from "react";
import moment from "moment";
import styled from "styled-components";
import {
  sendNewSubscription,
  setNewSubscription,
} from "../../redux/subscriptions/subscriptions.actions";

const PopUp = ({ setMembership }) => {
  const orders = useSelector((state) => state.orders.orders);
  const variations = useSelector((state) => state.shop.variations);
  const user = useSelector((state) => state.user.details);
  const dispatch = useDispatch();

  const newSubscription = useSelector(
    (state) => state.subscriptions.newSubscription
  );

  const removePopup = () => {
    dispatch(setNewSubscription({ popUp: false }));
  };

  const addSubscription = async () => {
    const body = {
      customer_id: user._id,
      status: "active",
      billingUnterval: 1,
      nextPaymentDate: moment()
        .endOf("month")
        .subtract(4, "days")
        .format("YY-MM-DD 0:0:0"),
      size: newSubscription.size,
      shipping: {
        firstName: newSubscription.first_name,
        lastName: newSubscription.last_name,
        address: newSubscription.address_1,
        city: newSubscription.city,
        postcode: newSubscription.postcode,
        country: newSubscription.country,
        email: user.email,
      },
      currency: "EUR",
      total: 13.99,
      product: 4132,
      productName: "Wijde Boxershort",
      mollieId: user.mollieId,
    };

    dispatch(sendNewSubscription(body));
    setMembership(false);
    dispatch(setNewSubscription({ popUp: false }));
  };

  return (
    <Wrapper>
      <Card>
        <h3>Klopt dit adres?</h3>
        <div className="mt-4">
          <span>{newSubscription.address_1}</span>
          <br />
          <span>{newSubscription.postcode}</span>
          <br />
          <span>{newSubscription.city}</span>
          <br />
          <span>{newSubscription.country}</span>
        </div>
        <Cross role="button" onClick={() => removePopup()}>
          X
        </Cross>
        <div className="text-left">
          <button
            className="btn btn-primary mt-4"
            onClick={() => addSubscription()}
          >
            Ja, opslaan
          </button>
        </div>
      </Card>
    </Wrapper>
  );
};

export default PopUp;

const Wrapper = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 20%);
  height: 100%;
  z-index: 999;
  width: 100%;
`;

const Card = styled.div`
  position: relative;
  width: 350px;
  margin: 0 auto;
  background: white;
  padding: 3rem 4rem;
  top: 15vh;

  span {
    line-height: 1.8;
  }
`;

const Cross = styled.div`
  position: absolute;
  right: 30px;
  top: 20px;
  font-size: 20px;
`;
