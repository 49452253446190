import {
  PencilFill,
  ArrowRepeat,
  Info,
  Bag,
  CalendarEvent,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import FrequentieFinder from "../../../helper/FrequentieFinder";
import StatusFinderAll from "../../../helper/StatusFinderAll";
import React from "react";
import { Spinner } from "react-bootstrap";

const RefreshCard = () => {
  const subscriptions = useSelector(
    (state) => state.subscriptions.subscriptions
  );

  const user = useSelector((state) => state.user.user);

  return (
    <div>
      <RepeatWrapper className="p-3">
        <Repeatsvg />
        {!subscriptions ? <Spinner color="blue" /> : null}
        <h4 className="pb-3">Meldingen</h4>
        <div className="row">
          <div className="col-12 pt-0 pb-3">Geen Meldingen! 🐶</div>
        </div>
      </RepeatWrapper>
    </div>
  );
};

export default RefreshCard;

const RepeatWrapper = styled.div`
  margin-top: 2rem;
  border: 0.0625rem solid #e8eef0;
  background-color: white;
  border-radius: 10px;
`;

const Repeatsvg = styled.div`
  margin: 1rem;
`;
