import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import {
  activateSubscription,
  setActiveTabSubscription,
} from "../../../../../../redux/subscriptions/subscriptions.actions";
import { createSubscriptionNote } from "../../../../../../redux/user/user.actions";

const HeractiverenPauze = () => {
  const dispatch = useDispatch();
  const subscriptions = useSelector((state) => state.subscriptions);
  const subscription = useSelector(
    (state) => state.subscriptions.activeSubscription
  );

  const handleButton = async () => {
    const body = {
      status: "active",
      subscription: subscription._id,
      nextPaymentDate: moment().format("YYYY-MM-26"),
      email: subscription.shipping.email,
      pauseDate: null,
      cancelDate: null,
      activateDate: null,
      reason: null,
      email: subscription?.shipping?.email,
      reactivateCount: subscription.reactivateCount + 1,
    };

    await dispatch(activateSubscription(body));

    dispatch(setActiveTabSubscription("Reactivated"));

    const note = {
      note:
        "Klant heeft zijn membership herstart vanaf " +
        moment(new Date()).format("YYYY-MM-DD 0:0:0"),
    };

    // dispatch(createSubscriptionNote(subscription.id, note));
  };

  return (
    <div className="p-3 text-center">
      {subscription.status === "pending-cancel" ? (
        <>
          <h3>Aan het stoppen</h3>
          <br />
          <p>
            Vanwege je maand opzegtermijn zal je
            <strong>
              {" "}
              begin{" "}
              {moment(subscription.nextPaymentDate)
                .add(1, "months")
                .format("MMMM")}{" "}
            </strong>
            nog één laatste boxershort ontvangen.
            <br />
            <br />
            Wil je hem opnieuw activeren?
          </p>
        </>
      ) : (
        <>
          <h3>Gestopt</h3>
          <p>Je membership is gestopt. Wil je hem opnieuw activeren?</p>
        </>
      )}
      <div className="text-center">
        {subscriptions.isLoading === false ? (
          <Button
            className="btn btn-primary mt-2 checkout-button"
            onClick={() => handleButton()}
          >
            Activeren
          </Button>
        ) : (
          <Button className="btn btn-primary mt-2">
            <StyledSpinner animation="border" variant="white" />
          </Button>
        )}
      </div>
    </div>
  );
};

export default HeractiverenPauze;

const Button = styled.button`
  color: white;
  border-radius: 30px;
  padding: 0.5rem 1rem;

  :hover {
    color: lightgrey;
  }
`;

const StyledSpinner = styled(Spinner)`
  width: 20px;
  height: 20px;
`;
