import { shopActionTypes } from "./shop.types";

const INITIAL_STATE = {
  variations: null,
  products: null,
  isLoading: false,
};

const shopReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case shopActionTypes.FETCH_VARIATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case shopActionTypes.FETCH_VARIATIONS_SUCCES:
      return {
        ...state,
        variations: action.payload,
        isLoading: false,
      };

    case shopActionTypes.FETCH_VARIATIONS_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false,
      };

    case shopActionTypes.FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case shopActionTypes.FETCH_PRODUCTS_SUCCES:
      return {
        ...state,
        products: action.payload,
        isLoading: false,
      };

    case shopActionTypes.FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default shopReducer;
