import { subscriptionsActionTypes } from "./subscriptions.types";
import { getSubscription } from "../../components/queries/getSubscriptions";
import { putSubscription } from "../../components/queries/putSubscriptions";
import { postNewSubscription } from "../../components/queries/setNewSubscription";
import { toast } from "react-toastify";
import { pauseSubscription } from "../../components/queries/pauseSubscription";
import { postActivateSubscription } from "../../components/queries/postActivateSubscription";
import { StopSubscription } from "../../components/queries/StopSubscription";
import { postRestartSubscription } from "../../components/queries/postRestartSubscription";
import { searchAddress } from "../../components/queries/searchAddress";

export const fetchSubscriptions = () => async (dispatch, getState) => {
  dispatch({ type: subscriptionsActionTypes.FETCH_SUBSCRIPTIONS_REQUEST });

  try {
    const token = getState().user.token;
    const subscriptions = await getSubscription(token);

    dispatch({
      type: subscriptionsActionTypes.FETCH_SUBSCRIPTIONS_SUCCES,
      payload: subscriptions,
    });

    var width = window.innerWidth;

    if (width > 768) {
      dispatch(setActiveSubscription(subscriptions[0]));
    }
  } catch (error) {
    dispatch({
      type: subscriptionsActionTypes.FETCH_SUBSCRIPTIONS_FAILURE,
      payload: error.data,
    });
    toast.warn("Er is iets mis gegaan", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }
};

export const postSubscriptions = (id, body) => async (dispatch, getState) => {
  dispatch({ type: subscriptionsActionTypes.POST_SUBSCRIPTIONS_REQUEST });

  try {
    const token = getState().user.token;
    const response = await putSubscription(id, body, token);

    await dispatch(fetchSubscriptions());

    await dispatch(setActiveSubscription(response.data));

    dispatch({
      type: subscriptionsActionTypes.POST_SUBSCRIPTIONS_SUCCES,
      payload: response,
    });
    toast.success("Je membership is aangepast!", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  } catch (error) {
    dispatch({
      type: subscriptionsActionTypes.POST_SUBSCRIPTIONS_FAILURE,
      payload: error.data,
    });
    toast.warn("Er is iets mis gegaan", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }
};

export const setActiveSubscription = (data) => {
  return {
    type: subscriptionsActionTypes.SET_ACTIVE_SUBSCRIPTION,
    payload: data,
  };
};

export const setActiveTabSubscription = (data) => {
  return {
    type: subscriptionsActionTypes.SET_ACTIVE_TAB_SUBSCRIPTION,
    payload: data,
  };
};

export const PauseSubscription = (body) => async (dispatch, getState) => {
  dispatch({ type: subscriptionsActionTypes.POST_PAUSESUBSCRIPTION_REQUEST });
  try {
    const token = getState().user.token;

    const result = await pauseSubscription(body, token);

    if (result !== undefined) {
      dispatch({
        type: subscriptionsActionTypes.POST_PAUSESUBSCRIPTION_SUCCES,
      });

      await dispatch(fetchSubscriptions());

      await dispatch(setActiveSubscription(result.data));

      toast.success("Je membership is gepauzeerd!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });

      dispatch(setActiveTabSubscription("Paused"));
    } else {
      dispatch({
        type: subscriptionsActionTypes.POST_PAUSESUBSCRIPTION_FAILURE,
      });
      toast.warn("Er is iets mis gegaan!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  } catch (error) {
    dispatch({
      type: subscriptionsActionTypes.POST_PAUSESUBSCRIPTION_FAILURE,
    });
    toast.warn("Er is iets mis gegaan!", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }
};

export const activateSubscription = (body) => async (dispatch, getState) => {
  dispatch({
    type: subscriptionsActionTypes.POST_ACTIVATE_SUBSCRIPTION_REQUEST,
  });
  try {
    const token = getState().user.token;

    const result = await postActivateSubscription(body, token);

    if (result !== undefined) {
      dispatch({
        type: subscriptionsActionTypes.POST_ACTIVATE_SUBSCRIPTION_SUCCES,
      });

      await dispatch(fetchSubscriptions());

      await dispatch(setActiveSubscription(result.data));

      toast.success("Je membership is geactiveerd!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      dispatch({
        type: subscriptionsActionTypes.POST_ACTIVATE_SUBSCRIPTION_FAILURE,
      });
      toast.warn("Er is iets mis gegaan!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  } catch (error) {
    dispatch({
      type: subscriptionsActionTypes.POST_ACTIVATE_SUBSCRIPTION_FAILURE,
    });
    toast.warn("Er is iets mis gegaan!", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }
};

export const QuitSubscription = (body) => async (dispatch, getState) => {
  dispatch({ type: subscriptionsActionTypes.POST_QUIT_SUBSCRIPTION_REQUEST });
  try {
    const token = getState().user.token;

    const result = await StopSubscription(body, token);

    if (result !== undefined) {
      dispatch({
        type: subscriptionsActionTypes.POST_QUIT_SUBSCRIPTION_SUCCES,
      });

      await dispatch(fetchSubscriptions());

      await dispatch(setActiveSubscription(result.data));

      toast.success("Je membership is gestopt!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });

      dispatch(setActiveTabSubscription("Quited"));
    } else {
      dispatch({
        type: subscriptionsActionTypes.POST_QUIT_SUBSCRIPTION_FAILURE,
      });
      toast.warn("Er is iets mis gegaan!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  } catch (error) {
    dispatch({
      type: subscriptionsActionTypes.POST_QUIT_SUBSCRIPTION_FAILURE,
    });
    toast.warn("Er is iets mis gegaan!", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }
};

export const restartSubscription = (body) => async (dispatch, getState) => {
  dispatch({
    type: subscriptionsActionTypes.POST_RESTART_SUBSCRIPTION_REQUEST,
  });
  try {
    const token = getState().user.token;

    const result = await postRestartSubscription(body, token);

    if (result !== undefined) {
      dispatch({
        type: subscriptionsActionTypes.POST_RESTART_SUBSCRIPTION_SUCCES,
      });

      await dispatch(fetchSubscriptions());

      await dispatch(setActiveSubscription(result.data));

      toast.success("Je membership is geactiveerd!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      dispatch({
        type: subscriptionsActionTypes.POST_RESTART_SUBSCRIPTION_FAILURE,
      });
      toast.warn("Er is iets mis gegaan!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  } catch (error) {
    dispatch({
      type: subscriptionsActionTypes.POST_RESTART_SUBSCRIPTION_FAILURE,
    });
    toast.warn("Er is iets mis gegaan!", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }
};

export const sendNewSubscription = (body) => async (dispatch, getState) => {
  dispatch({ type: subscriptionsActionTypes.POST_NEW_SUBSCRIPTIONS_REQUEST });

  try {
    const token = getState().user.token;
    const response = await postNewSubscription(token, body);

    await dispatch(fetchSubscriptions());

    dispatch({
      type: subscriptionsActionTypes.POST_NEW_SUBSCRIPTIONS_SUCCES,
      payload: response,
    });
    toast.success("Je nieuwe membership is toegevoegd!", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  } catch (error) {
    dispatch({
      type: subscriptionsActionTypes.POST_NEW_SUBSCRIPTIONS_FAILURE,
      payload: error.data,
    });
    toast.warn("Er is iets mis gegaan", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }
};

export const setNewSubscription = (data) => {
  return {
    type: subscriptionsActionTypes.SET_NEW_SUBSCRIPTION,
    payload: data,
  };
};

export const lookUpAddress = (body) => async (dispatch, getState) => {
  const houseNumber = body.house_number + body.addition;

  const url =
    "https://geocode.search.hereapi.com/v1/geocode?apiKey=mA7Ttxym09iTApy8B3poCp7v3RtEybhG1SZ-oiXAcxw&q=" +
    houseNumber +
    "+" +
    body.postcode;

  const result = await searchAddress(url);

  if (result.data.items[0]) {
    const resultBody = {
      address_1:
        result.data.items[0].address.street +
        " " +
        result.data.items[0].address.houseNumber,
      country: "Nederland",
      city: result.data.items[0].address.city,
      postcode: result.data.items[0].address.postalCode,
      state: result.data.items[0].address.state,
      popUp: true,
    };

    dispatch({
      type: subscriptionsActionTypes.SET_NEW_SUBSCRIPTION,
      payload: resultBody,
    });
  } else {
    toast.warn("Je adres is niet gevonden.", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }
};
