export const userActionTypes = {
  FETCH_TOKEN_REQUEST: "FETCH_TOKEN_REQUEST",
  FETCH_TOKEN_SUCCES: "FETCH_TOKEN_SUCCES",
  FETCH_TOKEN_FAILURE: "FETCH_TOKEN_FAILURE",
  FETCH_TOKEN_NEW_PW: "FETCH_TOKEN_NEW_PW",
  NEW_PW_CANCEL: "NEW_PW_CANCEL",
  RESET_PW_SUCCES: "RESET_PW_SUCCES",

  FETCH_USER_REQUEST: "FETCH_USER_REQUEST",
  FETCH_USER_SUCCES: "FETCH_USER_SUCCES",
  FETCH_USER_FAILURE: "FETCH_USER_FAILURE",

  FETCH_USER_AND_DATA_REQUEST: "FETCH_USER_AND_DATA_REQUEST",
  FETCH_USER_AND_DATA_SUCCES: "FETCH_USER_AND_DATA_SUCCES",
  FETCH_USER_AND_DATA_FAILURE: "FETCH_USER_AND_DATA_FAILURE",

  POST_USER_REQUEST: "POST_USER_REQUEST",
  POST_USER_SUCCES: "POST_USER_SUCCES",
  POST_USER_FAILURE: "POST_USER_FAILURE",

  REMOVE_USER_TOKEN: "REMOVE_USER_TOKEN",
  SET_ACTIVE_PAGE: "SET_ACTIVE_PAGE",

  CHANGE_METHOD_REQUEST: "CHANGE_METHOD_REQUEST",
  CHANGE_METHOD_SUCCES: "CHANGE_METHOD_SUCCES",
  CHANGE_METHOD_FAILLURE: "CHANGE_METHOD_FAILLURE",

  FETCH_MANDATE_REQUEST: "FETCH_MANDATE_REQUEST",
  FETCH_MANDATE_SUCCESS: "FETCH_MANDATE_SUCCESS",
  FETCH_MANDATE_FAILURE: "FETCH_MANDATE_FAILURE",

  RESTORE_CART_REQUEST: "RESTORE_CART_REQUEST",
  RESTORE_CART_SUCCES: "RESTORE_CART_SUCCES",
  RESTORE_CART_FAILURE: "RESTORE_CART_FAILURE",

  REACTIVATE_WITH_DISCOUNT: "REACTIVATE_WITH_DISCOUNT",
  REACTIVATE_WITH_DISCOUNT_FAILURE: "REACTIVATE_WITH_DISCOUNT_FAILURE",
};
