import { combineReducers } from "redux";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "./user/user.reducer.js";
import subscriptionsReducer from "./subscriptions/subscriptions.reducer";
import ordersReducer from "./orders/orders.reducer";
import shopReducer from "./shop/shop.reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const appReducer = combineReducers({
  user: userReducer,
  orders: ordersReducer,
  subscriptions: subscriptionsReducer,
  shop: shopReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "SIGNOUT_REQUEST") {
    storage.removeItem("persist:root");

    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
