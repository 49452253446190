import { ordersActionTypes } from "./orders.types";

const INITIAL_STATE = {
  orders: null,
  orderDetail: null,
  isLoading: false,
};

const ordersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ordersActionTypes.FETCH_ORDERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ordersActionTypes.FETCH_ORDERS_SUCCES:
      return {
        ...state,
        orders: action.payload,
        isLoading: false,
      };

    case ordersActionTypes.FETCH_ORDERS_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false,
      };

    case ordersActionTypes.SET_ORDER_DETAIL:
      return {
        ...state,
        orderDetail: action.payload,
        showDetail: true,
      };

    case ordersActionTypes.HIDE_ORDER_DETAIL:
      return {
        ...state,
        showDetail: false,
      };

    default:
      return state;
  }
};

export default ordersReducer;
