import React from "react";
import styled from "styled-components";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { lookUpAddress } from "../../../../redux/subscriptions/subscriptions.actions";
import { useDispatch } from "react-redux";

const Address = ({ setPage }) => {
  const dispatch = useDispatch();
  const [address, setAddress] = useState({
    postcode: "",
    house_number: "",
    addition: "",
    country: "Nederland",
  });

  const handleClick = () => {
    const body = address;

    dispatch(lookUpAddress(body));
  };

  return (
    <Card className="col-12">
      <Inner>
        <Form>
          <Title className="h3">Adresgegevens</Title>
          <LineItem>
            <span className="p">Postcode*</span>
            <br />
            <Input
              type="text"
              className="form-control"
              required
              value={address.postcode}
              onChange={(e) =>
                setAddress({ ...address, postcode: e.target.value })
              }
            />
          </LineItem>
          <div className="row">
            <LineItem className="col-6">
              <span className="p">Huisnummer*</span>
              <br />
              <Input
                type="number"
                className="form-control"
                value={address.house_number}
                required
                onChange={(e) =>
                  setAddress({ ...address, house_number: e.target.value })
                }
              />
            </LineItem>
            <LineItem className="col-6">
              <span className="p">Toevoeging</span>
              <Input
                type="text"
                className="form-control"
                value={address.addition}
                onChange={(e) =>
                  setAddress({ ...address, addition: e.target.value })
                }
              />
            </LineItem>
            <LineItem className="col-12">
              <span className="p">Land</span>
              <Input
                type="text"
                disabled
                className="form-control"
                value={address.country}
                onChange={(e) =>
                  setAddress({ ...address, addition: e.target.value })
                }
              />
            </LineItem>
          </div>
          <LineItem className="text-right">
            <Button
              className="btn btn-secondary mr-3"
              onClick={() => setPage("Size")}
            >
              Terug
            </Button>
            <Button className="btn btn-primary" onClick={() => handleClick()}>
              Verder
            </Button>
          </LineItem>
        </Form>
      </Inner>
    </Card>
  );
};

export default Address;

const Card = styled.div`
  padding: 7rem 3rem;
  margin-top: 2rem;
  background-color: white;
  border-radius: 10px;
  background-color: #204368;
`;

const Inner = styled.div`
  margin: 0 auto;
  text-align: center;
`;

const Form = styled.div`
  padding: 2rem;
  text-align: left;
  margin: 0 auto;
  width: 400px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
`;

const Input = styled.input`
  width: 100%;
  padding: 1rem;
  border: black solid 1px;
  border-radius: 4px;
  margin-top: 0.5rem;
`;

const LineItem = styled.div`
  margin: 0 0 1rem 0;
`;

const Title = styled.div`
  margin-bottom: 2rem;
`;

const Button = styled.button``;
