import axios from "axios";
import { fetchUrl } from "../../fetchurl";

export const ResetPassword = async (body) => {
  const result = await PasswordReset(body);

  const data = result.data;

  return data;
};

const PasswordReset = async (body) => {
  return axios
    .post(fetchUrl + "/login/forgot", body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch((err) => console.log(err));
};
