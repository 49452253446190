export const OrderStatus = (order) => {

  console.log(order)
  
  if (order.fulfillment_status === "fulfilled") {
    return "Verzonden"
  } else if (order.fulfillment_status === "partial") {
    return "Gedeeltelijk verzonden"
  } else if (order.financial_status === "unpaid") {
    return "Niet betaald"
  } else if (order.fulfillment_status === null) {
    return "In behandeling"
  } else {

  }
};
