import moment from "moment";

const SubscriptionStatus = (subscription, orders) => {
  const today = moment().format();

  if (orders) {
    if (orders.length === 1 && subscription.status === "active") {
      const creationDay = moment(subscription.createdAt)
        .add(4, "days")
        .format();

      if (today < creationDay) {
        return "new";
      }
    } else if (
      subscription.status === "pending-cancel" ||
      subscription.status === "cancelled"
    ) {
      return "cancelled";
    } else if (
      subscription.status === "pending-pause" ||
      subscription.status === "paused"
    ) {
      return "paused";
    }
  }
};

export default SubscriptionStatus;
