export const subscriptionsActionTypes = {
  FETCH_SUBSCRIPTIONS_REQUEST: "FETCH_SUBSCRIPTIONS_REQUEST",
  FETCH_SUBSCRIPTIONS_SUCCES: "FETCH_SUBSCRIPTIONS_SUCCES",
  FETCH_SUBSCRIPTIONS_FAILURE: "FETCH_SUBSCRIPTIONS_FAILURE",

  POST_SUBSCRIPTIONS_REQUEST: "POST_SUBSCRIPTIONS_REQUEST",
  POST_SUBSCRIPTIONS_SUCCES: "POST_SUBSCRIPTIONS_SUCCES",
  POST_SUBSCRIPTIONS_FAILURE: "POST_SUBSCRIPTIONS_FAILURE",

  SET_NEW_SUBSCRIPTION: "SET_NEW_SUBSCRIPTION",

  SET_ACTIVE_SUBSCRIPTION: "SET_ACTIVE_SUBSCRIPTION",
  SET_ACTIVE_TAB_SUBSCRIPTION: "SET_ACTIVE_TAB_SUBSCRIPTION",

  POST_NEW_SUBSCRIPTIONS_REQUEST: "POST_NEW_SUBSCRIPTIONS_REQUEST",
  POST_NEW_SUBSCRIPTIONS_SUCCES: "POST_NEW_SUBSCRIPTIONS_SUCCES",
  POST_NEW_SUBSCRIPTIONS_FAILURE: "POST_NEW_SUBSCRIPTIONS_FAILURE",

  POST_PAUSESUBSCRIPTION_SUCCES: "POST_PAUSESUBSCRIPTION_SUCCES",
  POST_PAUSESUBSCRIPTION_REQUEST: "POST_PAUSESUBSCRIPTION_REQUEST",
  POST_PAUSESUBSCRIPTION_FAILURE: "POST_PAUSESUBSCRIPTION_FAILURE",

  POST_ACTIVATE_SUBSCRIPTION_SUCCES: "POST_ACTIVATE_SUBSCRIPTION_SUCCES",
  POST_ACTIVATE_SUBSCRIPTION_REQUEST: "POST_ACTIVATE_SUBSCRIPTION_REQUEST",
  POST_ACTIVATE_SUBSCRIPTION_FAILURE: "POST_ACTIVATE_SUBSCRIPTION_FAILURE",

  POST_RESTART_SUBSCRIPTION_SUCCES: "POST_RESTART_SUBSCRIPTION_SUCCES",
  POST_RESTART_SUBSCRIPTION_REQUEST: "POST_RESTART_SUBSCRIPTION_REQUEST",
  POST_RESTART_SUBSCRIPTION_FAILURE: "POST_RESTART_SUBSCRIPTION_FAILURE",

  POST_QUIT_SUBSCRIPTION_SUCCES: "POST_QUIT_SUBSCRIPTION_SUCCES",
  POST_QUIT_SUBSCRIPTION_REQUEST: "POST_QUIT_SUBSCRIPTION_REQUEST",
  POST_QUIT_SUBSCRIPTION_FAILURE: "POST_QUIT_SUBSCRIPTION_FAILURE",
};
