import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  StoplightsFill,
  Cash,
  Mailbox2,
  Stack,
  ArrowRepeat,
  BrushFill,
} from "react-bootstrap-icons";

const LineItems = ({ setItem }) => {
  const subscription = useSelector(
    (state) => state.subscriptions.activeSubscription
  );

  return (
    <div>
      <h3 className="pt-3 pb-3 pl-3">
        Waarom wil je jouw Membership wijzigen?
      </h3>
      <LineItem
        className="row m-0 "
        role="button"
        onClick={() => setItem("frequentie")}
      >
        <div className="col-1 p-0 align-top">
          <ArrowRepeat color="#007bff" />
        </div>
        <div className="col-11">Ik wil mijn frequentie wijzigen</div>
      </LineItem>
      <LineItem
        role="button"
        className="row m-0 "
        onClick={() => setItem("pauze")}
      >
        <span className="col-1 p-0 align-top">
          <StoplightsFill color="#007bff" />
        </span>
        <div className="col-11 ">Ik heb even pauze nodig</div>
      </LineItem>
      <LineItem
        className="row m-0 "
        role="button"
        onClick={() => setItem("genoeg")}
      >
        <div className="col-1 p-0 align-top">
          <Stack color="#007bff" />
        </div>
        <div className="col-11 "> Ik heb genoeg boxershorts </div>
      </LineItem>
      <LineItem
        className="row m-0 "
        role="button"
        onClick={() => setItem("print")}
      >
        <div className="col-1 p-0 align-top">
          <BrushFill color="#007bff" />
        </div>
        <div className="col-11 "> Ik vind de prints niet mooi </div>
      </LineItem>
      <LineItem
        className="row m-0 "
        role="button"
        onClick={() => setItem("geld")}
      >
        <div className="col-1 p-0 align-top">
          <Cash color="#007bff" />
        </div>
        <div className="col-11">Vanwege financiële redenen</div>
      </LineItem>
      <LineItemLast
        className="row m-0 "
        role="button"
        onClick={() => setItem("levering")}
      >
        <div className="col-1 p-0 align-top">
          <Mailbox2 color="#007bff" />
        </div>
        <div className="col-11">Door problemen met levering</div>
      </LineItemLast>
    </div>
  );
};

export default LineItems;

const LineItem = styled.div`
  padding: 1rem 1rem 1rem;
  border-bottom: 1px solid lightgray;

  :hover {
    background: #f8f8f8;
  }
`;

const LineItemLast = styled.div`
  padding: 1rem 1rem 1rem;

  :hover {
    background: #f8f8f8;
  }
`;
