import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { setActiveTabSubscription } from "../../../../../redux/subscriptions/subscriptions.actions";
import Gif from "../../../../../assets/dog-cute.gif";
import DecideNotice from "../../../../../helper/DecideNotice";

const BevestigingQuit = () => {
  const dispatch = useDispatch();

  const subscription = useSelector(
    (state) => state.subscriptions.activeSubscription
  );

  const user = useSelector((state) => state.user?.details) || null;
  const orders = useSelector((state) => state.orders?.orders) || null;

  const notice = DecideNotice(user, orders);

  return (
    <Wrapper>
      <div className="mb-3">
        <span className="h3">Vaarwel {subscription.shipping.first_name}!</span>
      </div>
      <img src={Gif} alt="dog-cry" className="w-100 mb-4" />
      <span className="p">
        Jammer dat je het membership voorgoed stop zet, er stond nog zoveel
        moois op de planning.
      </span>
      {notice === "opzegtermijn" ? (
        <InsideBox>
          <span className="font-weight-bold w-100">Handige info</span>
          <div className="mt-2">
            Je laatste afschrijving vindt plaats aan het einde van{" "}
            {moment(subscription.nextPaymentDate).format("MMMM YYYY")}. Deze
            boxershort ontvang je vervolgens aan het begin van{" "}
            {moment(subscription.nextPaymentDate)
              .add(1, "months")
              .format("MMMM YYYY")}
            . Je kan je membership op ieder moment weer activeren.
          </div>
        </InsideBox>
      ) : (
        <>
          <br />
          <br />
          Je kan je membership op ieder moment weer activeren.
        </>
      )}
      <div className="mt-4">
        <button
          className="btn btn-primary"
          onClick={() => dispatch(setActiveTabSubscription(null))}
        >
          Sluiten
        </button>
      </div>
    </Wrapper>
  );
};

export default BevestigingQuit;

const Wrapper = styled.div`
  background-color: white;
  padding: 3rem;
  height: 100%;
  -webkit-box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
`;

const InsideBox = styled.div`
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 20px;
  margin-top: 2rem;
  width: 100%;
`;
