import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import {
  activateSubscription,
  setActiveTabSubscription,
} from "../../../../../../redux/subscriptions/subscriptions.actions";
import { createSubscriptionNote } from "../../../../../../redux/user/user.actions";
import nextPaymentDate from "../../../../../../helper/nextPaymentDate";

const HeractiverenPauze = () => {
  const dispatch = useDispatch();
  const subscriptions = useSelector((state) => state.subscriptions);
  const subscription = useSelector(
    (state) => state.subscriptions.activeSubscription
  );

  const pauseDate = subscription.pauseDate;
  const activateDate = subscription.activateDate;

  const handleButton = async () => {
    const body = {
      status: "active",
      subscription: subscription._id,
      nextPaymentDate: moment().format("YYYY-MM-26"),
      email: subscription?.shipping?.email,
      activateDate: null,
      reason: null,
      email: subscription?.shipping?.email,
      reactivateCount: subscription.reactivateCount + 1,
    };

    await dispatch(activateSubscription(body));

    dispatch(setActiveTabSubscription("Reactivated"));

    const note = {
      note:
        "Klant heeft zijn membership van pauze gehaald vanaf " +
        moment(new Date()).format("YYYY-MM-DD 0:0:0"),
    };

    dispatch(createSubscriptionNote(subscription.id, note));
  };

  return (
    <div className="p-3 text-center">
      {subscription.status === "pending-pause" ? (
        <>
          <h3>Aan het pauzeren</h3>
          <p>
            Vanwege je maand opzegtermijn zal je
            <strong>
              {" "}
              begin{" "}
              {moment(subscription.nextPaymentDate)
                .add(1, "months")
                .format("MMMM")}{" "}
            </strong>
            nog één laatste boxershort ontvangen.
          </p>
        </>
      ) : (
        <>
          <h3>Gepauzeerd</h3>
        </>
      )}
      <PauseWidget className="mt-3 mb-3 text-center">
        <div className="mb-3">Je huidige pauze periode</div>
        <span>
          {moment(subscription.nextPaymentDate).format("MMMM YYYY")} -{" "}
          {moment(activateDate).format("MMMM YYYY")}
        </span>
      </PauseWidget>
      <div className="text-center mt-5">
        <p>Wil je hem nu opnieuw activeren?</p>
        {subscriptions.isLoading === false ? (
          <Button
            className="btn btn-primary checkout-button"
            onClick={() => handleButton()}
          >
            Activeren
          </Button>
        ) : (
          <Button className="btn btn-primary">
            <StyledSpinner animation="border" variant="white" />
          </Button>
        )}
      </div>
    </div>
  );
};

export default HeractiverenPauze;

const Button = styled.button`
  color: white;
  border-radius: 30px;
  padding: 0.5rem 1rem;

  :hover {
    color: lightgrey;
  }
`;

const StyledSpinner = styled(Spinner)`
  width: 20px;
  height: 20px;
`;

const PauseWidget = styled.div`
  span {
    border: 1px solid black;
    border-radius: 20px;
    padding: 0.5rem 2rem;
  }
`;
