import React from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import styled from "styled-components";
import { PauseSubscription } from "../../../../../../../../redux/subscriptions/subscriptions.actions";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import DecideNotice from "../../../../../../../../helper/DecideNotice";

const Form = ({ setQuit, activeItem, setFinance }) => {
  const subscription = useSelector(
    (state) => state.subscriptions.activeSubscription
  );
  const subscriptions = useSelector((state) => state.subscriptions);
  const user = useSelector((state) => state.user.details);
  const orders = useSelector((state) => state.orders.orders);

  const dispatch = useDispatch();

  const [frequency, setFrequency] = useState(0);

  if (!orders || !user) {
    return "laden";
  }

  const notice = DecideNotice(user, orders);

  const handlePause = async () => {
    const createdDate = moment(user.createdAt).format();
    const noticeDate = moment(createdDate).add(10, "days").format();

    if (frequency === 0) {
      toast.error("Je moet nog een keuze maken.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      const months = parseFloat(frequency);

      //Datum van activeren
      const activationDate = moment().add(months, "months").toDate();

      const body = {
        endTerm: noticeDate,
        status: "paused",
        subscription: subscription._id,
        pauseDate: moment().toDate(),
        activateDate: activationDate,
        reason: activeItem,
        email: subscription?.shipping?.email,
      };

      await dispatch(PauseSubscription(body));
    }
  };

  const quitBoxer = () => {
    if (activeItem === "geld" && !subscription?.discount) {
      setFinance(true);
    } else {
      setQuit(true);
    }
  };

  return (
    <div className="p-3">
      <h3>Pauzeren</h3>
      <span>
        Stel hieronder gemakkelijk je pauze periode in.{" "}
        {notice === "opzegtermijn"
          ? "Er wordt automatisch rekening gehouden met je maand opzegtermijn."
          : null}
      </span>
      <div className="mt-4">
        <div className="mb-2">
          <span className="p">Hoelang wil je pauzeren?</span>
        </div>
        <form className="row  ml-auto mr-auto mb-3">
          <div className="col-6 pt-2 pb-2 pl-0">
            <Label
              className={
                frequency === "1"
                  ? "border-dark font-weight-bold border-4 col-12"
                  : "col-12"
              }
            >
              Één maand
              <input
                type="radio"
                value="1"
                name="adjust"
                checked={frequency === "1"}
                onChange={(e) => setFrequency(e.target.value)}
              />
            </Label>
          </div>
          <div className="col-6 pt-2 pb-2 pl-0">
            <Label
              className={
                frequency === "2"
                  ? "border-dark font-weight-bold border-4 col-12"
                  : "col-12"
              }
            >
              Twee maanden
              <input
                type="radio"
                value="2"
                name="adjust"
                checked={frequency === "2"}
                onChange={(e) => setFrequency(e.target.value)}
              />
            </Label>
          </div>
          <div className="col-6 pt-2 pb-2 pl-0">
            <Label
              className={
                frequency === "3"
                  ? "border-dark font-weight-bold border-4 col-12"
                  : "col-12"
              }
            >
              Drie maanden
              <input
                type="radio"
                value="3"
                name="adjust"
                checked={frequency === "3"}
                onChange={(e) => setFrequency(e.target.value)}
              />
            </Label>
          </div>
          <div className="col-6 pt-2 pb-2 pl-0">
            <Label
              className={
                frequency === "4"
                  ? "border-dark border-4 font-weight-bold col-12"
                  : "col-12"
              }
            >
              Vier maanden
              <input
                type="radio"
                value="4"
                name="adjust"
                checked={frequency === "4"}
                onChange={(e) => setFrequency(e.target.value)}
              />
            </Label>
          </div>
        </form>
      </div>
      <div className="text-center">
        {subscriptions.isLoading === false ? (
          <Button
            className="btn btn-primary mt-4 checkout-button"
            onClick={() => handlePause()}
          >
            Pauzeer Membership
          </Button>
        ) : (
          <Button className="btn btn-primary mt-4">
            <StyledSpinner animation="border" variant="white" />
          </Button>
        )}
      </div>
      <div className="mt-4 text-center ">
        <SecondButton className="btn" onClick={() => quitBoxer()}>
          Ik wil stoppen
        </SecondButton>
      </div>
    </div>
  );
};

export default Form;

const SecondButton = styled.div`
  background-color: white;
  border: 0px solid;
  color: #8f8f8f;
`;

const Label = styled.label`
  text-align: left;
  padding: 1.25rem 1rem 1rem 1rem;
  border: 2px solid #d8e0e3;
  width: 100%;
  height: 100%;
  background-color: white;

  & :nth-child(2) {
    border-bottom: 0px;
  }

  & > input[type="radio"] {
    visibility: hidden;
    height: 0px;
    width: 0px;
  }

  & > div > div > input[type="radio"] {
    visibility: hidden;
    height: 0px;
    width: 0px;
  }

  & > div > div > span {
    margin-left: -5px;
  }
`;

const Button = styled.button`
  color: white;
  border-radius: 30px;
  padding: 0.5rem 1rem;

  :hover {
    color: lightgrey;
  }
`;

const StyledSpinner = styled(Spinner)`
  width: 20px;
  height: 20px;
`;
