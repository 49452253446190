import { getOrders } from "../../components/queries/getOrders";
import { ordersActionTypes } from "./orders.types";
import { getInvoice } from "../../components/queries/getInvoice";

export const fetchOrders = () => async (dispatch, getState) => {
  dispatch({ type: ordersActionTypes.FETCH_ORDERS_REQUEST });

  try {
    const token = getState().user.token;
    const response = await getOrders(token);

    const orders =  response.orders

    dispatch({
      type: ordersActionTypes.FETCH_ORDERS_SUCCES,
      payload: orders,
    });
  } catch (error) {
    dispatch({
      type: ordersActionTypes.FETCH_ORDERS_FAILURE,
      payload: error.data,
    });
  }
};

export const setDetailOrder = (data) => async (dispatch, getState) => {
  const token = getState().user.token;

  if (data.invoiceId) {
    const invoice = await getInvoice(token, data.invoiceId);

    const newBody = {
      ...data,
      invoice: invoice,
    };

    dispatch({
      type: ordersActionTypes.SET_ORDER_DETAIL,
      payload: newBody,
    });
  } else {
    dispatch({
      type: ordersActionTypes.SET_ORDER_DETAIL,
      payload: data,
    });
  }
};

export const hideDetailOrder = (data) => async (dispatch, getState) => {
  dispatch({
    type: ordersActionTypes.HIDE_ORDER_DETAIL,
    payload: data,
  });
};
