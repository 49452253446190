import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Details from "./Membership/Details";
import Cards from "./Membership/Details/Cards";
import { useState } from "react";
import New from "./Membership/New";
import PopUp from "../../components/newSubscriptionPopUp/PopUp";
import SpinnerComponent from "../../components/Spinner/SpinnerComponent";
import React from "react";

function Refresh() {
  const subscriptions = useSelector(
    (state) => state.subscriptions.subscriptions
  );

  var width = window.innerWidth;

  const variations = useSelector((state) => state.shop.variations);

  const activeSubscription = useSelector(
    (state) => state.subscriptions.activeSubscription
  );

  const newSubscription = useSelector(
    (state) => state.subscriptions.newSubscription
  );

  const orders = useSelector((state) => state.orders.orders);

  const [createMembership, setMembership] = useState(false);

  if (!subscriptions || !variations || !orders) {
    return <SpinnerComponent />;
  }

  return (
    <>
      {newSubscription.popUp === true ? (
        <PopUp setMembership={setMembership} />
      ) : null}
      <Wrapper>
        <WrapperMember>
          <div className="container">
            <RefreshHeader className="row w-100 mr-0 ml-0">
              <div className="col-12">
                {createMembership === false ? (
                  <>
                    {width < 768 || width > 768 ? (
                      <p className="h3 mt-4">Jouw Memberships</p>
                    ) : null}
                    <Cards setMembership={setMembership} />
                    {width > 768 ? <Details /> : null}
                  </>
                ) : (
                  <New setMembership={setMembership} />
                )}
              </div>
            </RefreshHeader>
          </div>
        </WrapperMember>
        <StyledContainer autoClose={2500} hideProgressBar={true} />
      </Wrapper>
    </>
  );
}

export default Refresh;

const Wrapper = styled.div``;

const WrapperMember = styled.div`
  background-color: #f5f5f5;
`;

const RefreshHeader = styled.div`
  padding: 0rem 0rem;
`;

const StyledContainer = styled(ToastContainer).attrs()`
  .Toastify__toast--error {
    background-color: #dc3545;
  }
  .Toastify__toast--success {
    background-color: #007bff;
  }
`;
