import React from "react";
import moment from "moment";

const nextPaymentDate = (subscription) => {
  const date = moment(subscription.next_payment_date_gmt)
    .add(1, "months")
    .format("MMMM");

  if (subscription.status === "active") {
    return <>Levering: begin {date}</>;
  } else if (
    subscription.status === "pending-cancel" ||
    subscription.status === "pending-pause"
  ) {
    return <>Laatste levering: begin {date}</>;
  } else {
    return <>geen</>;
  }
};

export default nextPaymentDate;
