import React, { useState, useEffect } from "react";
import { useHistory, Redirect, useLocation } from "react-router-dom";
import Logo from "../../assets/Udzwart.svg";
import { ChevronRight } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchToken } from "../../redux/user/user.actions";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "react-bootstrap/Button";
import SpinnerComponent from "../../components/Spinner/SpinnerComponent";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const search = useLocation().search;

  const discountCode = new URLSearchParams(search).get("discount");
  const loginData = useSelector((state) => state.user.loginData);

  const [username, setUsername] = useState(loginData?.username || null);
  const [password, setPassword] = useState(loginData?.password || null);

  const token = useSelector((state) => state.user.token);
  const isLoading = useSelector((state) => state.user.isLoading);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user.newPW && user.loginData) {
      history.push("/nieuw-wachtwoord");
    }
  }, [dispatch, token, history, user]);

  const HandleLogin = (e) => {
    e.preventDefault();

    const loginData = {
      username: username,
      password: password,
    };

    if (discountCode) {
      history.push("/discount?discount=" + discountCode + "&login=true");
    } else {
      history.push("/");
    }

    dispatch(fetchToken(loginData));
  };

  const handleHome = () => {
    window.location = "https://underdog.nl";
  };

  return (
    <>
      {!token ? (
        <Wrapper>
          <LogoWrapper>
            <div className="container">
              <span onClick={() => handleHome()} role="button">
                <img src={Logo} alt="Logo-UD-white" max-width="175px"></img>
              </span>
            </div>
          </LogoWrapper>
          {isLoading ? (
            <SpinnerComponent />
          ) : (
            <div className="container">
              <div className="bg-white col-12 col-sm-6 col-lg-4 p-4 m-auto">
                <p className="h3">Inloggen</p>
                <form onSubmit={HandleLogin}>
                  <p className="p mt-4">E-mailadres</p>
                  <input
                    className="form-control"
                    defaultValue={username}
                    type="text"
                    onChange={(e) => setUsername(e.target.value)}
                    autoComplete="username"
                  />
                  <br />
                  <p className="p">Wachtwoord</p>
                  <input
                    className="form-control"
                    type="password"
                    defaultValue={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="current-password"
                  />
                  <div className="text-primary mt-3 mb-3">
                    <span
                      role="button"
                      onClick={() => history.push("/wachtwoord-vergeten")}
                    >
                      <ChevronRight />
                      Wachtwoord vergeten?
                    </span>
                  </div>
                  <StyledButton className="btn-primary" type="submit">
                    Doorgaan
                  </StyledButton>
                </form>
              </div>
            </div>
          )}
          <StyledContainer />
        </Wrapper>
      ) : !discountCode ? (
        <Redirect to="/dashboard" />
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default Login;

const StyledContainer = styled(ToastContainer).attrs()`
  .Toastify__toast--error {
    background-color: #dc3545;
  }
  .Toastify__toast--success {
    background-color: #007bff;
  }
`;

const Wrapper = styled.div`
  background-color: #f5f5f5;
  height: 100vh;
`;

const LogoWrapper = styled.div`
  background-color: white;
  text-align: center;
  padding: 0.75rem 0 0.5rem;
  margin-bottom: 5rem;
`;

const StyledButton = styled(Button)`
  padding: 0.75rem;
  border-radius: 2px;
  border: none;
  width: 100%;
`;
