import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { postUser } from "../../../redux/user/user.actions";
import React from "react";
import styled from "styled-components";

const DetailsAdress = ({ setLoader }) => {
  const user = useSelector((state) => state.user.details);

  const dispatch = useDispatch();
  const [Password, setPassword] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleAccount = (e) => {
    e.preventDefault();

    if (Password.password !== Password.confirmPassword) {
      alert("Wachtwoorden verschillen");
    } else {
      const body = {
        password: Password.password,
        email: user.email,
      };

      dispatch(postUser(body));
    }
  };

  return (
    <Card>
      <h4>Wachtwoord</h4>
      <Form onSubmit={handleAccount}>
        <div className="row mt-4">
          <div className="col-12 col-md-6">Nieuw wachtwoord</div>
          <div className="col-12 col-md-6">
            <Form.Control
              placeholder="Nieuw wachtwoord"
              type="password"
              value={Password.password}
              autoComplete="username"
              onChange={(e) =>
                setPassword({ ...Password, password: e.target.value })
              }
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-md-6">Herhaal wachtwoord</div>
          <div className="col-12 col-md-6">
            <Form.Control
              type="password"
              autoComplete="password"
              value={Password.confirmPassword}
              onChange={(e) =>
                setPassword({ ...Password, confirmPassword: e.target.value })
              }
              placeholder="Herhaal wachtwoord"
            />
          </div>
        </div>
        <div className="mt-3 text-right">
          <Button className="btn btn-primary" type="submit">
            Opslaan
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default DetailsAdress;

const Card = styled.div`
  margin-top: 2rem;
  padding: 2rem;
  border: 0.0625rem solid #e8eef0;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
`;
