import moment from "moment";
import React from "react";
import style from "./Order.module.css";
import { ArrowRight } from "react-bootstrap-icons";
import "moment/locale/nl";
import { useSelector, useDispatch } from "react-redux";
import { OrderStatus } from "../../helper/orders/orderStatus/orderStatus";
import { setDetailOrder } from "../../redux/orders/orders.actions";
import styled from "styled-components";
import { OrderImage } from "../../helper/orders/orderImage/OrderImage";

function Order() {
  const dispatch = useDispatch();

  const orders = useSelector((state) => state.orders.orders);
  const variations = useSelector((state) => state.shop.variations);

  if (!orders || !variations) {
    return (
      <Wrapper className="w-100">
        <div className={style.order}>Laden...</div>
      </Wrapper>
    );
  }

  const handleClick = (order) => {
    dispatch(setDetailOrder(order));
  };

  const sortedOrders = orders.sort(function(a,b){
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(a.processed_at) - new Date(b.processed_at);
  })

  return (
    <>
      {sortedOrders &&
        sortedOrders
          .map((order) => (
            <Wrapper
              key={order._id}
              role="button"
              className="pr-3 pl-3 col-12 col-sm-3 pb-3"
              onClick={() => handleClick(order)}
            >
              <OrdersWrapper className="row mr-0 ml-0">
                <Image className="col-12 p-3">
                  {OrderImage(order, variations)}
                </Image>
                <div className="col-12 p-3 col-md-12 text-center">
                  <div className="row">
                    <div className="col-12">
                      <span style={{ textTransform: "capitalize" }}>
                        {moment(order.processed_at).format("DD") < 26
                          ? moment(order.processed_at).local("nl").format("DD MMMM yy")
                          : moment(order.processed_at)
                              .local("nl")
                              .add(1, "months")
                              .format("DD MMMM yy")}
                      </span>
                    </div>
                    <div className="col-12 text-center mt-2">
                      <span className="mr-4">Status</span>
                      <Status className="bg-primary text-white">
                        {OrderStatus(order)}
                      </Status>
                    </div>
                  </div>
                </div>
              </OrdersWrapper>
            </Wrapper>
          ))
          .reverse()}
    </>
  );
}

export default Order;

const Wrapper = styled.div`
  border-left: 0.0625rem solid #e8eef0;
  border-right: 0.0625rem solid #e8eef0;
  border-bottom: 0.0625rem solid #e8eef0;
  background-color: white;
`;

const Status = styled.span`
  border-radius: 20px;
  padding: 0.3rem 0.8rem;
  font-size: 12px;
  margin-left: -15px;
`;

const OrdersWrapper = styled.div``;

const StyledArrowRight = styled(ArrowRight)`
  color: black;
  margin: 10px 0 0 40px;
  float: right;
  cursor: pointer;
`;

const Image = styled.div`
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
