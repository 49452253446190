import "react-toastify/dist/ReactToastify.css";
import React, { useState } from "react";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { postSubscriptions } from "../../../../../redux/subscriptions/subscriptions.actions";

const AdresCard = (activeSubscription) => {
  const subscription = activeSubscription.subscription;
  const subscriptions = useSelector((state) => state.subscriptions);

  const dispatch = useDispatch();

  const [details, setDetails] = useState({
    street:
      subscription.shipping?.address?.charAt(0).toUpperCase() +
      subscription.shipping?.address?.slice(1),
    postcode: subscription?.shipping?.postcode,
    city: subscription?.shipping?.city,
  });

  const handleDetails = (e) => {
    e.preventDefault();

    const body = {
      shipping: {
        ...subscription.shipping,
        address: details.street,
        postcode: details.postcode,
        city: details.city,
      },
    };

    dispatch(postSubscriptions(subscription._id, body));
  };

  return (
    <Card>
      <div className="col-12">
        <h3>Verzend gegevens wijzigen</h3>
        <Label>Postcode*</Label>
        <input
          className="form-control"
          value={details.postcode}
          onChange={(e) => setDetails({ ...details, postcode: e.target.value })}
          required
        />
        <Label>Straat*</Label>
        <input
          className="form-control"
          value={details.street}
          onChange={(e) => setDetails({ ...details, street: e.target.value })}
          required
        />
        <Label>Stad*</Label>
        <input
          className="form-control"
          value={details.city}
          onChange={(e) => setDetails({ ...details, city: e.target.value })}
          required
        />
        <Label>Land</Label>
        <input className="form-control" defaultValue="Nederland" disabled />
      </div>
      <div className="col-12 mt-4 text-right">
        {subscriptions.isLoading === false ? (
          <Button
            className="btn btn-primary mt-4 checkout-button"
            onClick={(e) => handleDetails(e)}
          >
            Opslaan
          </Button>
        ) : (
          <Button className="btn btn-primary mt-4">
            <StyledSpinner animation="border" variant="white" />
          </Button>
        )}
      </div>
    </Card>
  );
};

export default AdresCard;

const Card = styled.div`
  background: white;
  width: 100%;
  border: 4px;
  padding: 3rem 2rem;
  -webkit-box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
  h3 {
    font-weight: 700;
  }
`;

const Label = styled.div`
  margin-bottom: 5px;
  margin-top: 1.5rem;
`;

const StyledSpinner = styled(Spinner)`
  width: 20px;
  height: 20px;
`;
