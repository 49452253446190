const FrequentieFinder = (subscription) => {
  if (!subscription) {
    return "Geen";
  } else if (subscription.billing_interval === "1") {
    return "maandelijks";
  } else if (subscription.billing_interval === "2") {
    return "elke 2 maanden";
  } else if (subscription.billing_interval === "3") {
    return "elke 3 maanden";
  } else if (subscription.billing_interval === "4") {
    return "elke 4 maanden";
  }
};

export default FrequentieFinder;
