import React from "react";
import styled from "styled-components";

const SpinnerComponent = () => {
  return (
    <CenterLoading>
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only"></span>
      </div>
    </CenterLoading>
  );
};

export default SpinnerComponent;

const CenterLoading = styled.div`
  position: relative;
  background: #f5f5f5;
  text-align: center;
  height: 80vh;
  padding-top: 40vh;
`;
