import "react-toastify/dist/ReactToastify.css";
import LineItems from "./LineItems";
import styled from "styled-components";
import { useState } from "react";
import Pauze from "./Reasons/Pauze/Pauze";
import { ChevronLeft } from "react-bootstrap-icons";
import HeractiverenPauze from "./Heractiveren/HeractiverenPauze";
import Frequentie from "./Reasons/frequentie/Frequentie";
import HeractiverenGestopt from "./Heractiveren/HeractiverenGestopt";
import SubscriptionStatus from "../../../../../helper/SubscriptionStatus";
import { useSelector } from "react-redux";
import SpinnerComponent from "../../../../../components/Spinner/SpinnerComponent";
import React from "react";

const ChangeStatus = (activeSubscription, { setActive }) => {
  const user = useSelector((state) => state.user?.details) || null;

  const subscription = useSelector(
    (state) => state.subscriptions.activeSubscription
  );

  const orders = useSelector((state) => state.orders.orders);

  const [activeItem, setItem] = useState();

  if (user === null || subscription === null) {
    return <SpinnerComponent />;
  }

  const status = SubscriptionStatus(activeSubscription.subscription, orders);

  return (
    <Card>
      {status === "new" ? (
        <InnerBox className="bg-primary text-white">
          <h3>Even geduld!</h3>
          <div>
            Ons systeem is druk bezig met het verwerken van je eerste
            boxershort. Je kan 4 dagen na aanmelden je membership hier
            gemakkelijk wijzigen.
          </div>
        </InnerBox>
      ) : status === "paused" ? (
        <InnerBox>
          <HeractiverenPauze />
        </InnerBox>
      ) : status === "cancelled" ? (
        <InnerBox>
          <HeractiverenGestopt />
        </InnerBox>
      ) : (
        <div>
          {activeItem !== undefined ? (
            <ReasonBar>
              <StyledChevronLeft
                color="white"
                onClick={() => setItem(undefined)}
                type="button"
              />
              <span>Kies een reden</span>
            </ReasonBar>
          ) : null}
          {activeItem === "pauze" ? (
            <InnerBox>
              <Pauze setItem={setItem} activeItem={activeItem} />
            </InnerBox>
          ) : activeItem === "genoeg" ? (
            <InnerBox>
              <Pauze setItem={setItem} activeItem={activeItem} />
            </InnerBox>
          ) : activeItem === "frequentie" ? (
            <InnerBox>
              <Frequentie setItem={setItem} activeItem={activeItem} />
            </InnerBox>
          ) : activeItem === "geld" ? (
            <InnerBox>
              <Pauze setItem={setItem} activeItem={activeItem} />
            </InnerBox>
          ) : activeItem === "levering" ? (
            <InnerBox>
              <Pauze setItem={setItem} activeItem={activeItem} />
            </InnerBox>
          ) : activeItem === "print" ? (
            <InnerBox>
              <Pauze setItem={setItem} activeItem={activeItem} />
            </InnerBox>
          ) : (
            <InnerBox>
              <LineItems setItem={setItem} />
            </InnerBox>
          )}
        </div>
      )}
    </Card>
  );
};

export default ChangeStatus;

const Card = styled.div`
  background: white;
  width: 100%;
  border: 4px;
  -webkit-box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);

  h3 {
    font-weight: 700;
  }
`;

const InnerBox = styled.div`
  @media (min-width: 768px) {
    padding: 3rem 2rem;
  }
`;

const ReasonBar = styled.div`
  padding: 0.5rem 0.4rem;
  background: black;
  width: 100%;
  position: relative;
  text-align: center;

  span {
    color: white;
    text-align: center;
    font-weight: 700;
  }
`;

const StyledChevronLeft = styled(ChevronLeft)`
  position: absolute;
  left: 10px;
  top: 12.5px;
`;
