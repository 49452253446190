import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import BoxerImage from "../../../../assets/wide-boxer-v2.png";
import { setActiveSubscription } from "../../../../redux/subscriptions/subscriptions.actions";
import FrequentieFinder from "../../../../helper/FrequentieFinder";
import Accordion from "./Accordion";
import nextPaymentDate from "../../../../helper/nextPaymentDate";
import React from "react";

function SubscriptionCard({ setMembership }) {
  const dispatch = useDispatch();

  const subscriptions = useSelector(
    (state) => state.subscriptions.subscriptions
  );

  var width = window.innerWidth;

  const activeSubscription = useSelector(
    (state) => state.subscriptions?.activeSubscription
  );

  const handleClick = (subscription) => {
    dispatch(setActiveSubscription(subscription));
  };

  if (width < 768 && activeSubscription) {
    return <Accordion activeSubscription={activeSubscription} />;
  } else {
    return (
      <div className="row">
        {subscriptions.map((subscription) => (
          <SubscriptionBox
            role="button"
            className="col-6 col-sm-4 col-md-3"
            onClick={() => handleClick(subscription)}
            key={subscription.id}
          >
            <InsideBox>
              <div className="row">
                <Status className="col-12">
                  {subscription.status === "active" ? (
                    <Active>
                      <span>Actief</span>
                    </Active>
                  ) : subscription.status === "paused" ? (
                    <Paused>
                      <span>Gepauzeerd</span>
                    </Paused>
                  ) : subscription.status === "pending-pause" ? (
                    <Paused>
                      <span>Aan het pauzeren</span>
                    </Paused>
                  ) : subscription.status === "pending-cancel" ? (
                    <Cancelled>
                      <span>Aan het stoppen</span>
                    </Cancelled>
                  ) : subscription.status === "on-hold" ? (
                    <Cancelled>
                      <span>In de wacht</span>
                    </Cancelled>
                  ) : (
                    <Cancelled>
                      <span>Gestopt</span>
                    </Cancelled>
                  )}
                </Status>
                <div className="col-12 mt-4">
                  <Text>{subscription.productName}</Text> <br />
                  <Text className="text-uppercase">{subscription.size}</Text>
                  <br />
                  <Text>
                    {subscription.nextPaymentDate
                      ? FrequentieFinder(subscription)
                      : null}
                  </Text>
                </div>
              </div>
              <HR />
              <span
                className="text-secondary font-weight-light"
                style={{ fontSize: "14px" }}
              >
                {nextPaymentDate(subscription)}
              </span>
              <ArrowButton>
                {activeSubscription?._id === subscription._id ? (
                  <StyledChevron>
                    <ChevronUp size="16px" weight="700" color="white" />
                  </StyledChevron>
                ) : (
                  <InActiveStyledChevron>
                    <ChevronDown size="16px" weight="700" color="blue" />
                  </InActiveStyledChevron>
                )}
              </ArrowButton>
            </InsideBox>
          </SubscriptionBox>
        ))}
        <>
          <SubscriptionBox
            role="button"
            className="col-6 col-sm-4 col-md-3"
            onClick={() => setMembership(true)}
          >
            <InsideBox style={{ backgroundColor: "#007BFF" }}>
              <div className="row">
                <div className="col-12 mt-3"></div>
                <div className="center w-100">
                  <img
                    alt="boxershort-wide"
                    src={BoxerImage}
                    width="100px"
                    height="100px"
                  />
                </div>
                <Toevoegen>
                  <Text>Member toevoegen</Text>
                </Toevoegen>
              </div>
            </InsideBox>
          </SubscriptionBox>
        </>
      </div>
    );
  }
}

export default SubscriptionCard;

const SubscriptionBox = styled.div`
  padding: 2rem 1rem 0;
`;

const InsideBox = styled.div`
  padding: 1rem 1rem 1rem;
  background: white;
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  border-radius: 6px;
`;

const Active = styled.div`
  span {
    background: green;
  }
`;

const Cancelled = styled.div`
  span {
    background: red;
  }
`;

const Paused = styled.div`
  span {
    background: orange;
  }
`;

const Status = styled.div`
  color: white;
  position: absolute;
  top: -5px;
  font-size: 12px;

  div {
    span {
      padding: 0.2rem 1.25rem;
      border-radius: 20px;
    }
  }
`;

const HR = styled.div`
  border-bottom: 1px solid rgba(242, 242, 242);
  width: 100%;
  margin: 0.6rem 0 0;
`;

const StyledChevron = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: #007bff;
  margin: 1rem auto 0;

  svg {
    vertical-align: -3px;
  }
`;

const InActiveStyledChevron = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 25px;

  margin: 1rem auto 0;

  svg {
    vertical-align: -3px;
  }
`;

const ArrowButton = styled.div``;

const Text = styled.span`
  font-size: 14px;
`;

const Toevoegen = styled.div`
  width: 100%;
  position: absolute;
  bottom: -8px;
  padding: 0px;

  span {
    background-color: #333;
    border-radius: 30px;
    padding: 3px 10px;
    color: white;
    width: 100%;
    font-size: 12px;
  }
`;
