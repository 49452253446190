import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";
import { ChangePayment } from "../../../redux/user/user.actions";
import styled from "styled-components";
import React from "react";

const Payment = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.details);
  const orders = useSelector((state) => state.orders.orders);

  if (!orders) {
    return <> </>;
  }

  const handleMethod = (e) => {
    e.preventDefault();

    const customerId = user._doc.mollieId

    const body = {
      name: user.firstName + " " + user.lastName,
      email: user.email,
      locale: "nl_NL",
      metadata: user._id,
    };

    dispatch(ChangePayment(body, customerId));
  };
  return (
    <>
      <Wrapper className="w-100 p-4">
        <h4>Betaalmethode</h4>
        <p className="h6">SEPA</p>
        <div>
          <p className="font-weight-light p-3 mt-4 border">
            Betaal eenmalig € 0,01 om te wisselen van de rekening waar we onze
            incasso's op uitvoeren.
          </p>
        </div>
        <div className="text-right font-weight-bold">
          <Button
            className=" font-weight-bold"
            onClick={handleMethod}
            type="submit"
          >
            Wijzigen
          </Button>
        </div>
      </Wrapper>
    </>
  );
};

export default Payment;

const Wrapper = styled.div`
  margin-top: 2rem;
  border: 0.0625rem solid #e8eef0;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
`;
