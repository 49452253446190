import React from "react";
import styled from "styled-components";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { setNewSubscription } from "../../../../redux/subscriptions/subscriptions.actions";
import { useDispatch } from "react-redux";

const Name = ({ setPage }) => {
  const dispatch = useDispatch();

  const [person, setPerson] = useState({
    first_name: "",
    last_name: "",
  });

  const handleClick = () => {
    dispatch(setNewSubscription(person));
    setPage("Address");
  };

  return (
    <Card>
      <Inner>
        <Form>
          <Title className="h3">Wie is de ontvanger?</Title>
          <LineItem>
            <span className="p">Voornaam</span>
            <br />
            <Input
              type="text"
              className="form-control"
              required
              value={person.first_name}
              onChange={(e) =>
                setPerson({ ...person, first_name: e.target.value })
              }
            />
          </LineItem>
          <LineItem>
            <span className="p">Achternaam</span>
            <br />
            <Input
              type="text"
              className="form-control"
              required
              onChange={(e) =>
                setPerson({ ...person, last_name: e.target.value })
              }
            />
          </LineItem>
          <LineItem className="text-right">
            <Button
              className="btn btn-secondary mr-3"
              onClick={() => setPage("Size")}
            >
              Terug
            </Button>
            <Button className="btn btn-primary" onClick={() => handleClick()}>
              Verder
            </Button>
          </LineItem>
        </Form>
      </Inner>
    </Card>
  );
};

export default Name;

const Card = styled.div`
  padding: 7rem 0;
  margin-top: 2rem;
  background-color: white;
  border-radius: 10px;
  background-color: #204368;
`;

const Inner = styled.div`
  margin: 0 auto;
  text-align: center;
`;

const Form = styled.div`
  padding: 2rem;
  text-align: left;
  margin: 0 auto;
  width: 400px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
`;

const Input = styled.input`
  width: 100%;
  padding: 1rem;
  border: black solid 1px;
  border-radius: 4px;
  margin-top: 0.5rem;
`;

const LineItem = styled.div`
  margin: 0 0 1.5rem 0;
`;

const Title = styled.div`
  margin-bottom: 2rem;
`;

const Button = styled.button``;
