import moment from "moment";

const DecideNotice = (user, orders) => {
  const today = moment().format();

  const createdDate = moment(user.createdAt).format();
  const noticeDate = moment(createdDate).add(10, "days").format();

  const filteredOrders = orders.filter((order) => order.type !== "resend");

  const cutDateRaw = "2021-11-23";
  const cutDate = moment(cutDateRaw).format();

  if (createdDate < cutDate) {
    return "direct";
  } else if (filteredOrders.length === 1 && noticeDate >= today) {
    return "direct";
  } else {
    return "opzegtermijn";
  }
};

export default DecideNotice;
