import React from "react";
import moment from "moment";

import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import Gif from "../../../../../../../assets/sad-dog.gif";
import Spinner from "react-bootstrap/Spinner";
import {
  QuitSubscription,
  setActiveTabSubscription,
} from "../../../../../../../redux/subscriptions/subscriptions.actions";
import DecideNotice from "../../../../../../../helper/DecideNotice";

const Quit = ({ setQuit, setItem, activeItem }) => {
  const dispatch = useDispatch();

  const subscription = useSelector(
    (state) => state.subscriptions.activeSubscription
  );

  const subscriptions = useSelector((state) => state.subscriptions);

  const user = useSelector((state) => state.user.details);
  const orders = useSelector((state) => state.orders.orders);

  const createdDate = moment(user.createdAt).format();
  const noticeDate = moment(createdDate).add(10, "days").format();

  if (!orders || !user) {
    return "laden";
  }

  const notice = DecideNotice(user, orders);

  const handleBack = () => {
    setQuit(false);
    setItem(undefined);
  };

  const handleClick = async () => {
    if (notice === "opzegtermijn") {
      const body = {
        endTerm: noticeDate,
        subscription: subscription._id,
        status: "pending-cancel",
        reason: activeItem,
        email: subscription?.shipping?.email,
      };

      dispatch(QuitSubscription(body));

      setTimeout(() => {
        dispatch(setActiveTabSubscription("Quited"));
      }, 3000);
    } else {
      const body = {
        endTerm: noticeDate,
        subscription: subscription._id,
        status: "cancelled",
        cancelDate: moment().toDate(),
        reason: activeItem,
        email: subscription?.shipping?.email,
      };

      dispatch(QuitSubscription(body));
      dispatch(setActiveTabSubscription("Quited"));
    }
  };

  return (
    <div className="p-3">
      <h3>Membership stoppen</h3>
      <GifImg src={Gif} alt="cry-gif" />
      {notice === "opzegtermijn" ? (
        <span>
          Er wordt automatisch rekening gehouden met je maand opzegtermijn.
        </span>
      ) : null}
      <div className="text-center mt-3 mb-3">
        {subscriptions.isLoading === false ? (
          <Button className="btn mt-4 btn-md" onClick={() => handleClick()}>
            Stop Membership
          </Button>
        ) : (
          <Button className="btn btn-primary mt-4">
            <StyledSpinner animation="border" variant="white" />
          </Button>
        )}
      </div>
      <div className="mt-4 text-center">
        <SecondButton className="btn" onClick={() => handleBack()}>
          Hé? Ik wil nu terug!
        </SecondButton>
      </div>
    </div>
  );
};

export default Quit;

const SecondButton = styled.div`
  background-color: white;
  border: 0px solid;
  color: #8f8f8f;
`;

const Button = styled.button`
  background-color: grey;
  color: white;
  border-radius: 30px;
  padding: 0.5rem 1.5rem;

  :hover {
    color: lightgrey;
  }
`;

const GifImg = styled.img`
  border-radius: 10px;
  margin: 1rem 0;
  max-width: 100%;
`;

const StyledSpinner = styled(Spinner)`
  width: 20px;
  height: 20px;
`;
