import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { setActiveTabSubscription } from "../../../../../redux/subscriptions/subscriptions.actions";
import Gif from "../../../../../assets/dog-waving.gif";
import DecideNotice from "../../../../../helper/DecideNotice";

const BevestigingPauze = () => {
  const dispatch = useDispatch();

  const subscription = useSelector(
    (state) => state.subscriptions.activeSubscription
  );

  const activateDate = subscription.activateDate;

  const user = useSelector((state) => state.user?.details) || null;
  const orders = useSelector((state) => state.orders?.orders) || null;

  const notice = DecideNotice(user, orders);

  if (!subscription) {
    return (
      <Wrapper>
        <CenterLoading>
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">aan het laden...</span>
          </div>
        </CenterLoading>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="mb-3">
        <span className="h3">
          Tot snel {subscription?.shipping?.firstName}!
        </span>
      </div>
      <img src={Gif} alt="dog-waving" className="w-100 mb-4" />
      <span className="p">
        We missen je nu al.. Gelukkig kom je ooit weer terug.
      </span>
      <InsideBox>
        <span className="font-weight-bold w-100">Handige info</span>
        {notice === "opzegtermijn" ? (
          <div className="mt-4">
            Je laatste afschrijving is in{" "}
            <span className="font-weight-bold">
              {moment(subscription.nextPaymentDate).format("MMMM YYYY")}
            </span>
            . Deze boxershort ontvang je vervolgens aan het begin van{" "}
            {moment(subscription.nextPaymentDate)
              .add(1, "months")
              .format("MMMM YYYY")}
            . Je kan je membership op ieder moment weer activeren.
          </div>
        ) : null}
        <div className="mt-3">
          De eerstvolgende afschrijving na je pauze is eind{" "}
          <span className="font-weight-bold">
            {moment(activateDate).format("MMMM YYYY")}
          </span>
        </div>
        <div className="mt-3">
          Die boxershort ontvang je vervolgens in het begin van{" "}
          <span className="font-weight-bold">
            {moment(activateDate).add(1, "months").format("MMMM YYYY")}
          </span>
        </div>
      </InsideBox>
      <div className="mt-4">
        <button
          className="btn btn-primary"
          onClick={() => dispatch(setActiveTabSubscription(null))}
        >
          Sluiten
        </button>
      </div>
    </Wrapper>
  );
};

export default BevestigingPauze;

const Wrapper = styled.div`
  background-color: white;
  padding: 3rem;
  height: 100%;
  -webkit-box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
`;

const InsideBox = styled.div`
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 20px;
  margin-top: 2rem;
  width: 100%;
`;

const CenterLoading = styled.div`
  position: relative;
  background: #f5f5f5;
  text-align: center;
  height: 80vh;
  padding-top: 40vh;
`;
