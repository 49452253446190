import { subscriptionsActionTypes } from "./subscriptions.types";

const INITIAL_STATE = {
  subscriptions: null,
  isLoading: false,
  newSubscription: { popUp: false },
  activeSubscription: null,
  activeTab: null,
};

const subscriptionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case subscriptionsActionTypes.FETCH_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case subscriptionsActionTypes.FETCH_SUBSCRIPTIONS_SUCCES:
      return {
        ...state,
        subscriptions: action.payload,
        isLoading: false,
      };

    case subscriptionsActionTypes.FETCH_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false,
      };

    case subscriptionsActionTypes.POST_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case subscriptionsActionTypes.POST_SUBSCRIPTIONS_SUCCES:
      return {
        ...state,
        isLoading: false,
      };

    case subscriptionsActionTypes.POST_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false,
      };

    case subscriptionsActionTypes.SET_NEW_SUBSCRIPTION:
      return {
        ...state,
        newSubscription: { ...state.newSubscription, ...action.payload },
      };

    case subscriptionsActionTypes.SET_ACTIVE_SUBSCRIPTION:
      return {
        ...state,
        activeSubscription: action.payload,
      };

    case subscriptionsActionTypes.POST_PAUSESUBSCRIPTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case subscriptionsActionTypes.POST_PAUSESUBSCRIPTION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case subscriptionsActionTypes.POST_PAUSESUBSCRIPTION_SUCCES:
      return {
        ...state,
        isLoading: false,
      };

    case subscriptionsActionTypes.POST_ACTIVATE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case subscriptionsActionTypes.POST_ACTIVATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case subscriptionsActionTypes.POST_ACTIVATE_SUBSCRIPTION_SUCCES:
      return {
        ...state,
        isLoading: false,
      };

    case subscriptionsActionTypes.SET_ACTIVE_TAB_SUBSCRIPTION:
      return {
        ...state,
        activeTab: action.payload,
      };

    case subscriptionsActionTypes.POST_QUIT_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case subscriptionsActionTypes.POST_QUIT_SUBSCRIPTION_SUCCES:
      return {
        ...state,
        isLoading: false,
      };
    case subscriptionsActionTypes.POST_QUIT_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case subscriptionsActionTypes.POST_RESTART_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case subscriptionsActionTypes.POST_RESTART_SUBSCRIPTION_SUCCES:
      return {
        ...state,
        isLoading: false,
      };
    case subscriptionsActionTypes.POST_RESTART_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    default:
      return state;
  }
};

export default subscriptionsReducer;
