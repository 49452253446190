import axios from "axios";
import { fetchUrl } from "../../fetchurl";

export const postActivateSubscription = async (body, token) => {
  return axios
    .post(fetchUrl + "/user/activate-subscription/", body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    })
    .catch((err) => console.log(err));
};
