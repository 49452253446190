import axios from "axios";
import { fetchUrl } from "../../fetchurl";

export const getSubscription = async (token) => {
  const result = await fetchSubscription(token);

  const data = result.data;

  return data;
};

const fetchSubscription = async (token) => {
  return axios
    .get(fetchUrl + "/user/subscriptions", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    })
    .catch((err) => console.log(err));
};
