import React from "react";
import { useState } from "react";
import Form from "./Form/Form";
import Quit from "../Stoppen/Quit";

const Frequentie = ({ setItem }) => {
  const [quitTab, setQuit] = useState(false);

  return (
    <>
      {quitTab ? (
        <Quit setQuit={setQuit} setItem={setItem} />
      ) : (
        <Form setQuit={setQuit} />
      )}
    </>
  );
};

export default Frequentie;
