import { getVariations } from "../../components/queries/getVariations";
import { getProducts } from "../../components/queries/getProducts";
import { shopActionTypes } from "./shop.types";

export const fetchVariations = () => async (dispatch, getState) => {
  dispatch({ type: shopActionTypes.FETCH_VARIATIONS_REQUEST });

  try {
    const response = await getVariations();

    dispatch({
      type: shopActionTypes.FETCH_VARIATIONS_SUCCES,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: shopActionTypes.FETCH_VARIATIONS_FAILURE,
      payload: error.data,
    });
  }
};

export const fetchProducts = () => async (dispatch, getState) => {
  dispatch({ type: shopActionTypes.FETCH_PRODUCTS_REQUEST });

  try {
    const response = await getProducts();

    dispatch({
      type: shopActionTypes.FETCH_PRODUCTS_SUCCES,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: shopActionTypes.FETCH_PRODUCTS_FAILURE,
      payload: error.data,
    });
  }
};
