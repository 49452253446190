import axios from "axios";
import { fetchUrl } from "../../fetchurl";

export const getUser = async (token) => {
  const result = await fetchUser(token);

  const data = result.data;

  return data;
};

const fetchUser = async (token) => {
  return axios
    .get(fetchUrl + "/user/details", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    })
    .catch((err) => console.log(err));
};
