import { PencilFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import React from "react";

function Person() {
  const user = useSelector((state) => state.user.details);

  return (
    <Wrapper>
      <WrapperInside>
        <Details>
          <h4>Account gegevens</h4>
          <Item className="mt-3">
            <Topitem>Naam</Topitem>
            <span>
              {user?.first_name || null} {user?.last_name || null}
            </span>
          </Item>
          <Item>
            <Topitem>E-mail</Topitem>
            <span>{user?.email || null}</span>
          </Item>
          <Item>
            <Topitem>{user?.phone ? "Telefoon" : null}</Topitem>
            <span>{user?.phone?.startsWith(31) ? "+" : null}{user?.phone || null}</span>
          </Item>
        </Details>
        <InnerWrapper>
          <Link to="/details" style={{ textDecoration: "none" }}>
            <Beheren>
              <span>Account Beheren</span>
              <PencilFill size="14px" color="#333" className="ml-2" />
            </Beheren>
          </Link>
        </InnerWrapper>
      </WrapperInside>
    </Wrapper>
  );
}

export default Person;

const Topitem = styled.div`
  margin-bottom: 0.25rem;
`;

const Item = styled.div`
  margin-bottom: 1rem;

  :last-child {
    margin: 0px;
  }
`;

const Details = styled.div`
  padding: 2rem;
`;

const Wrapper = styled.div`
  margin-top: 2rem;
`;

const WrapperInside = styled.div`
  border: 0.0625rem solid #e8eef0;
  background-color: white;
  border-radius: 10px;
`;

const InnerWrapper = styled.div`
  border-top: 0.0625rem solid #e8eef0;
  padding: 1rem;
`;

const Beheren = styled.div`
  text-align: center;

  span {
    border-bottom: 0px;
    color: #333;
  }
`;
