import axios from "axios";
import { fetchUrl } from "../../fetchurl";

export const sendPasswordReset = async (body) => {
  const result = await PasswordReset(body);

  return result;
};

const PasswordReset = async (body) => {
  return axios
    .post(fetchUrl + "/login/forgot/change", body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch((err) => console.log(err));
};
