import axios from "axios";
import { fetchUrl } from "../../fetchurl";

export const postReactivateSubscription = async (body) => {
  return axios
    .post(fetchUrl + "/login/restart-subscription-discount/", body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch((err) => console.log(err));
};
