import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { setActiveTabSubscription } from "../../../../../redux/subscriptions/subscriptions.actions";
import Gif from "../../../../../assets/excited-dog.gif";

const BevestigingHeractivatie = () => {
  const dispatch = useDispatch();

  const subscription = useSelector(
    (state) => state.subscriptions.activeSubscription
  );

  return (
    <Wrapper>
      <div className="mb-3">
        <span className="h3">
          Welkom terug {subscription.shipping.firstName}!
        </span>
      </div>
      <img src={Gif} alt="dog-waving" className="w-100 mb-4" />
      <span>We hebben enorm veel zin om je weer boxershorts te leveren! </span>
      <div className="mt-4">
        <button
          className="btn btn-primary"
          onClick={() => dispatch(setActiveTabSubscription(null))}
        >
          Sluiten
        </button>
      </div>
    </Wrapper>
  );
};

export default BevestigingHeractivatie;

const Wrapper = styled.div`
  background-color: white;
  padding: 3rem;
  height: 100%;
  -webkit-box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
`;
