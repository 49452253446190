import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { Pencil } from "react-bootstrap-icons";
import AdresCard from "./AdresCard/AdresCard";
import SizeCard from "./SizeCard/sizeCard";
import ChangeStatus from "./StatusCard/ChangeStatus";
import { useSelector, useDispatch } from "react-redux";
import { setActiveTabSubscription } from "../../../../redux/subscriptions/subscriptions.actions";
import BevestigingHeractivatie from "./BevestigingCard/BevestigingHeractivatie";
import BevestigingQuit from "./BevestigingCard/BevestigingQuit";
import BevestigingPauze from "./BevestigingCard/BevestigingPauze";
import BevestigingFrequentie from "./BevestigingCard/BevestigingFrequentie";
import React from "react";

const Accordion = (activeSubscription) => {
  const subscription = activeSubscription.activeSubscription;

  var width = window.innerWidth;

  const dispatch = useDispatch();
  const setActive = (name) => {
    dispatch(setActiveTabSubscription(name));
  };

  const orders = useSelector((state) => state.orders.orders);

  const ActiveTab = useSelector((state) => state.subscriptions.activeTab);

  console.log(ActiveTab)

  return (
    <>
      <div className="row">
        {(width < 768 && !ActiveTab) || width >= 768 ? (
          <div className="col-12 col-md-5">
            <MemberShipping
              role="button"
              onClick={() => setActive("Shipping")}
              style={
                ActiveTab === "Shipping" || ActiveTab === null
                  ? { background: "#007bff", color: "white" }
                  : { background: "white" }
              }
            >
              <StyledPencil />
              <h4>Verzend gegevens</h4>
                <span>
                {subscription.shipping?.address?.charAt(0).toUpperCase() +
                  subscription.shipping?.address?.slice(1) || null}{" "}
              </span>{" "}
              <br />
              <span>{subscription.shipping?.postcode || null} </span>
              <span>
                {subscription.shipping?.city || null}
                <br />
                Nederland
              </span>
            </MemberShipping>
            <MemberSize
              role="button"
              onClick={() => setActive("Size")}
              style={
                ActiveTab === "Size"
                  ? { background: "#007bff", color: "white" }
                  : { background: "white" }
              }
            >
              <StyledPencil />
              <h4>Maat wijzigen</h4>
            </MemberSize>
            <MemberStatus
              role="button"
              onClick={() => setActive("Status")}
              className="row ml-0 mr-0"
              style={
                ActiveTab === "Status"
                  ? { background: "#007bff", color: "white" }
                  : { background: "white" }
              }
            >
              <div>
                <StyledPencilMember />
              </div>
              <div className="col-6 p-0">
                <h4>Status wijzigen</h4>
              </div>
              <div className="col-6 pl-3 pr-0">
                <Status>
                  {subscription.status === "active" ? (
                    <Active>
                      <span>Actief</span>
                    </Active>
                  ) : subscription.status === "paused" ? (
                    <Paused>
                      <span>Gepauzeerd</span>
                    </Paused>
                  ) : subscription.status === "pending-pause" ? (
                    <Paused>
                      <span>Aan het pauzeren</span>
                    </Paused>
                  ) : subscription.status === "pending-cancel" ? (
                    <Cancelled>
                      <span>Aan het stoppen</span>
                    </Cancelled>
                  ) : subscription.status === "on-hold" ? (
                    <Cancelled>
                      <span>In de wacht</span>
                    </Cancelled>
                  ) : (
                    <Cancelled>
                      <span>Gestopt</span>
                    </Cancelled>
                  )}
                </Status>
              </div>
            </MemberStatus>
          </div>
        ) : null}
        {width > 768 || ActiveTab ? (
          <div className="col-12 col-sm-7 ml-0 mr-0 p-1">
            {ActiveTab === "Shipping" ? (
              <AdresCard subscription={subscription} />
            ) : ActiveTab === "Size" ? (
              <SizeCard subscription={subscription} />
            ) : ActiveTab === "Status" ? (
              <ChangeStatus subscription={subscription} setActive={setActive} />
            ) : ActiveTab === "Paused" ? (
              <BevestigingPauze subscription={subscription} />
            ) : ActiveTab === "Quited" ? (
              <BevestigingQuit subscription={subscription} />
            ) : ActiveTab === "Reactivated" ? (
              <BevestigingHeractivatie subscription={subscription} />
            ) : ActiveTab === "Frequency" ? (
              <BevestigingFrequentie subscription={subscription} />
            ) : (
              <AdresCard subscription={subscription} />
            )}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Accordion;

const MemberDetails = styled.div`
  position: relative;
  padding: 1.5rem 2rem;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  span {
    line-height: 1.8;
  }

  h4 {
    font-size: 18px;
    font-weight: 700;
  }
`;

const MemberShipping = styled.div`
  position: relative;
  margin-top: 0.25rem;
  padding: 1.5rem 2rem;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  background: #f5f5f5;

  span {
    line-height: 1.8;
  }

  h4 {
    font-size: 18px;
    font-weight: 700;
  }
`;

const MemberSize = styled.div`
  position: relative;
  margin-top: 1rem;
  padding: 1.5rem 2rem 1rem;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  background: #f5f5f5;

  h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
  }
`;

const MemberStatus = styled.div`
  position: relative;
  margin-top: 1rem;
  margin-right: 0rem;
  margin-left: 0rem;
  padding: 1.5rem 2rem 1rem;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  background: #f5f5f5;

  h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
  }
`;

const StyledPencil = styled(Pencil)`
  position: absolute;
  right: 20px;
`;

const StyledPencilMember = styled(Pencil)`
  position: absolute;
  right: 20px;
`;

const Active = styled.div`
  span {
    background: green;
  }
`;

const Cancelled = styled.div`
  span {
    background: red;
  }
`;

const Status = styled.div`
  color: white;
  font-size: 12px;
  margin-top: 2px;

  div {
    span {
      padding: 0.2rem 1rem;
      border-radius: 20px;
    }
  }
`;

const Paused = styled.div`
  span {
    background: orange;
  }
`;
