import axios from "axios";
import { fetchUrl } from "../../fetchurl";

export const putLogin = async (body) => {
  const result = await postLogin(body);

  return result;
};

const postLogin = async (body) => {
  return axios
    .post(fetchUrl + "/login/token", body)
    .catch((err) => console.log(err));
};
