import axios from "axios";
import { fetchUrl } from "../../fetchurl";

export const getProducts = async () => {
  const result = await fetchProducts();

  const allProducts = result.data;

  return allProducts;
};

const fetchProducts = async () => {
  return axios
    .get(fetchUrl + "/shop/all-products", {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch((err) => {
      console.log(err);
    });
};
