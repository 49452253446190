import React, { useState } from "react";
import style from "./LoginPage.module.css";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { ReactComponent as Logo } from "../../assets/Udzwart.svg";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ResetPassword } from "../../components/queries/ResetPassword";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const history = useHistory();

  const sendEmail = (e) => {
    e.preventDefault();

    if (email === "") {
      toast.error("Je hebt geen email ingevuld!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else if (email.includes("@")) {
      const body = { email: email };

      ResetPassword(body);
      history.push("/wachtwoord-vergeten/succes");
    } else {
      toast.error("Je email is verkeerd.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  return (
    <div className={style.loginWrapper}>
      <div className={style.menubar}>
        <div
          className="container"
          onClick={() => history.push("/")}
          role="button"
        >
          <Logo />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-10 col-md-4 bg-white p-5 ml-auto mr-auto">
            <p className="h3 text-center">Wachtwoord vergeten</p>
            <form className="mt-4" onSubmit={sendEmail}>
              <span>E-mailadres</span>
              <input
                className={style.input}
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
              />
              <br />
              <div className="text-center">
                <Button className="mt-3 pt-2 pb-2 pr-4 pl-4 " type="submit">
                  Doorgaan
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <StyledContainer />
    </div>
  );
};

export default ForgotPassword;

const StyledContainer = styled(ToastContainer).attrs()`
  .Toastify__toast--error {
    background-color: #dc3545;
  }
  .Toastify__toast--success {
    background-color: #007bff;
  }
`;
