import { userActionTypes } from "./user.types";
import { getUser } from "../../components/queries/getUser";
import { putLogin } from "../../components/queries/putLogin";
import { fetchSubscriptions } from "../subscriptions/subscriptions.actions";
import { fetchOrders } from "../orders/orders.actions";
import { fetchVariations, fetchProducts } from "../shop/shop.actions";
import { putAccount } from "../../components/queries/putAccount";
import { toast } from "react-toastify";
import { changeMethod } from "../../components/queries/changeMethod";
import { postSubscriptionNote } from "../../components/queries/postSubscriptionNote";
import { fetchLostCart } from "../../components/queries/token";
import { postReactivateSubscription } from "../../components/queries/postReactivateSubscription";
import { fetchMandate } from "../../components/queries/fetchMandate";

export const fetchToken = (userData) => async (dispatch) => {
  dispatch({ type: userActionTypes.FETCH_TOKEN_REQUEST, payload: userData });

  try {
    const result = await putLogin(userData);

    if (result.status === 200) {
      dispatch({
        type: userActionTypes.FETCH_TOKEN_SUCCES,
        payload: result.data,
      });
    } else if (result.status === 201) {
      dispatch({
        type: userActionTypes.FETCH_TOKEN_NEW_PW,
        payload: result.data,
      });
    } else {
      dispatch({
        type: userActionTypes.FETCH_TOKEN_FAILURE,
        payload: result.data,
      });
      toast.error("Je wachtwoord is onjuist!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  } catch (error) {
    dispatch({
      type: userActionTypes.FETCH_TOKEN_FAILURE,
      payload: error.data,
    });
    toast.error("Je wachtwoord is onjuist!", {
      position: toast.POSITION.TOP_CENTER,
    });
  }
};

export const CancelNewPw = () => async (dispatch, getState) => {
  dispatch({ type: userActionTypes.NEW_PW_CANCEL });
};

export const fetchUser = () => async (dispatch, getState) => {
  dispatch({ type: userActionTypes.FETCH_USER_REQUEST });

  try {
    const token = getState().user.token;

    const response = await getUser(token);

    dispatch({
      type: userActionTypes.FETCH_USER_SUCCES,
      payload: response,
    });
  } catch (error) {
    dispatch({ type: userActionTypes.FETCH_USER_FAILURE, payload: error.data });
    toast.error("Er is iets fout gegaan met je profieldata", {
      position: toast.POSITION.TOP_CENTER,
    });
  }
};

export const setToken = (token) => (dispatch) => {
  dispatch({
    type: userActionTypes.FETCH_TOKEN_SUCCES,
    payload: token,
  });
};

export const fetchUserAndData = () => (dispatch) => {
  dispatch({ type: userActionTypes.FETCH_USER_AND_DATA_REQUEST });

  try {
    dispatch(fetchUser());
    dispatch(fetchSubscriptions());
    dispatch(fetchOrders());
    dispatch(fetchProducts());
    dispatch(fetchVariations());

    dispatch({
      type: userActionTypes.FETCH_USER_AND_DATA_SUCCES,
    });
  } catch (error) {
    dispatch({
      type: userActionTypes.FETCH_USER_AND_DATA_FAILURE,
      payload: error.data,
    });
    toast.error("Er is iets fout gegaan met inloggen", {
      position: toast.POSITION.TOP_CENTER,
    });
  }
};

export const createSubscriptionNote =
  (subscriptionId, body) => async (dispatch, getState) => {
    const token = getState().user.token;

    postSubscriptionNote(subscriptionId, body, token);
  };

export const postUser = (body) => async (dispatch, getState) => {
  dispatch({ type: userActionTypes.POST_USER_REQUEST });
  try {
    const token = getState().user.token;

    const user = await putAccount(body, token);

    dispatch({
      type: userActionTypes.POST_USER_SUCCES,
      payload: user.data.customer,
    });
    toast.success("Je profiel is aangepast!", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: userActionTypes.POST_USER_FAILURE,
      payload: error.data,
    });
    toast.warn("Er is iets fout gegaan", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }
};

export const checkMandate = (token) => async (dispatch, getState) => {
  dispatch({ type: userActionTypes.FETCH_MANDATE_REQUEST });
  try {
    console.log("checking");

    const result = await fetchMandate(token);

    console.log(result);

    dispatch({
      type: userActionTypes.FETCH_MANDATE_SUCCESS,
      payload: result,
    });
  } catch (error) {
    toast.warn("Er is iets fout gegaan", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }
};

export const removeUser = () => {
  return {
    type: "SIGNOUT_REQUEST",
    payload: null,
  };
};

export const ChangePayment =
  (body, customerId) => async (dispatch, getState) => {
    dispatch({ type: userActionTypes.CHANGE_METHOD_REQUEST });

    try {
      const token = getState().user.token;
      const user = getState().user;

      const response = await changeMethod(customerId, body, token);

      dispatch({
        type: userActionTypes.CHANGE_METHOD_SUCCES,
      });

      window.location = response._links.checkout.href;
    } catch (error) {
      dispatch({
        type: userActionTypes.CHANGE_METHOD_FAILLURE,
        payload: error.data,
      });

      toast.warn("Er is iets fout gegaan met je betaalmethode", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

export const setActivePage = (body) => async (dispatch, getState) => {
  dispatch({
    type: userActionTypes.SET_ACTIVE_PAGE,
    payload: body,
  });
};

export const getOldCart = (token) => async (dispatch, getState) => {
  dispatch({
    type: userActionTypes.RESTORE_CART_REQUEST,
  });

  const result = await fetchLostCart(token);

  if (result?.status === 200) {
    dispatch({
      type: userActionTypes.RESTORE_CART_SUCCES,
      payload: result.data,
    });
  } else {
    dispatch({
      type: userActionTypes.RESTORE_CART_FAILURE,
    });
  }
};

export const discountActivateSubscription =
  (body) => async (dispatch, getState) => {
    dispatch({
      type: userActionTypes.POST_USER_REQUEST,
    });

    const result = await postReactivateSubscription(body);

    if (result?.status === 200) {
      dispatch({
        type: userActionTypes.REACTIVATE_WITH_DISCOUNT,
        payload: result.data,
      });

      toast.success("Je membership is weer actief!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      dispatch({
        type: userActionTypes.REACTIVATE_WITH_DISCOUNT_FAILURE,
      });

      toast.error(
        "Er is iets fout gegaan. Neem contact op met onze klantenservice.",
        {
          position: toast.POSITION.BOTTOM_CENTER,
        }
      );
    }
  };

export const succesPWReset = (body) => async (dispatch, getState) => {
  dispatch({
    type: userActionTypes.RESET_PW_SUCCES,
    payload: body,
  });
};
