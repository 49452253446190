import axios from "axios";
import { fetchUrl } from "../../fetchurl";

export const getInvoice = async (token, id) => {
  const result = await fetchInvoice(token, id);

  const data = result?.data || [];

  return data;
};

const fetchInvoice = async (token, id) => {
  return axios
    .get(fetchUrl + "/user/order-invoice/" + id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    })
    .catch((err) => console.log(err));
};
