import styled from "styled-components";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { X } from "react-bootstrap-icons";
import { hideDetailOrder } from "../../redux/orders/orders.actions";
import { OrderStatus } from "../../helper/orders/orderStatus/orderStatus";
import { DetailsImage } from "../../helper/orders/orderDetails/image";

const OrderDetails = () => {
  const dispatch = useDispatch();

  const order = useSelector((state) => state.orders.orderDetail);
  const show = useSelector((state) => state.orders.showDetail);
  const variations = useSelector((state) => state.shop.variations);

  const handleHide = () => {
    dispatch(hideDetailOrder());
  };

  return (
    <>
      {show === true ? (
        <>
          <Background onClick={handleHide} />
          <Wrapper>
            <InsideWrapper>
              <StyledX role="button" size="35" onClick={handleHide} />
              <p className="h4 text-center">Bestelgegevens</p>
              <p
                className="h2 text-center mb-4  "
                style={{ textTransform: "capitalize" }}
              >
                {moment(order.processed_at).format("DD") < 26
                  ? moment(order.processed_at).local("nl").format("MMMM - YYYY")
                  : moment(order.processed_at)
                      .local("nl")
                      .add(1, "months")
                      .format("MMMM - YYYY")}
              </p>
              <hr className="mt-5" />
              <HRText>INFO</HRText>
              <div className="row mt-5 mr-0 ml-0">
                <div className="col-6  p-0">
                  <p className="text-secondary font-weight-light">Naam</p>
                  <p className="font-weight-light">
                    {order?.shipping_address?.first_name}{" "}
                    {order?.shipping_address?.last_name}
                  </p>
                </div>
                <div className="col-3  p-0">
                  <p className="text-secondary font-weight-light">Totaal</p>
                  <p className="font-weight-light">€ {order?.total_price}</p>
                </div>
                <div className="col-3  p-0">
                  <p className="text-secondary font-weight-light">Status</p>
                  <Status className="font-weight-light text-white bg-primary">
                    {OrderStatus(order)}
                  </Status>
                </div>
              </div>
              <div className="row mt-4 mr-0 ml-0">
                <div className="col-6 col-md-6  p-0">
                  <p className="text-secondary font-weight-light">
                    Bezorgadres
                  </p>
                  <p className="font-weight-light">
                    {order?.shipping_address?.city},{" "}
                    {order?.shipping_address?.zip},
                    <br />
                    {order?.shipping_address?.address1}
                  </p>
                </div>
                <div className="col-4 col-md-3 p-0">
                  <p className="text-secondary font-weight-light">Verzending</p>
                  <p className="font-weight-light">Gratis</p>
                </div>
                <div className="col-2 col-md-3 p-0">
                  <p className="text-secondary font-weight-light">ID</p>
                  <p className="font-weight-light">#{order?.name}</p>
                </div>
                {/* {order.invoice ? (
                  <>
                    <div className="col-4 col-md-3 p-0 pt-3">
                      <p className="text-secondary font-weight-light">
                        Factuur
                      </p>
                      <a href={order.invoice.url} target="_blank">
                        <p className="font-weight-light text-primary">
                          Bekijken
                        </p>
                      </a>
                    </div>{" "}
                    <div className="col-4 col-md-3 p-0 pt-3">
                      <p className="text-secondary font-weight-light">
                        Toegangscode
                      </p>
                      <p className="font-weight-light">
                        {order.invoice.public_view_code}
                      </p>
                    </div>
                  </>
                ) : null} */}
              </div>
              <hr className="mt-5" />
              <HRProduct>PRODUCTEN</HRProduct>
              <div>
                {order.line_items.map((product, index) => (
                  <div className="row" key={index}>
                    <div className="col-4 mt-2">
                      <Image>{DetailsImage(product, variations)}</Image>
                    </div>
                    <div className="col-5 mt-3">
                      <div className="font-weight-bold">
                        {product.sku.includes("WIJD")
                          ? "Wijde Boxershort"
                          : "Boxershort"}
                      </div>
                      <div className="text-secondary ">{product?.name}</div>
                    </div>
                    <div className="col-3 mt-4 text-right">
                      <div>€{product?.price}</div>
                    </div>
                  </div>
                ))}
              </div>
            </InsideWrapper>
          </Wrapper>
        </>
      ) : null}
    </>
  );
};

export default OrderDetails;

const Wrapper = styled.div`
  position: fixed;
  top: 0%;
  background-color: white;
  padding: 8rem 1.5rem;
  margin: 0 auto;
  overflow-y: scroll;

  @media (min-width: 700px) {
    overflow-y: scroll;
    position: fixed;
    max-width: 500px;
    width: 100%;
    left: 50%;
    margin-left: -250px;
    top: 1%;
    background-color: white;
    padding: 6rem 3rem;
    overflow-y: hidden;
    overflow-x: hidden;
  }
`;

const InsideWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Image = styled.div`
  > img {
    max-width: 80px;
    max-height: 80px;
  }
`;

const StyledX = styled(X)`
  position: absolute;
  right: 0px;
  margin-top: -50px;
  color: grey;
`;

const HRText = styled.p`
  margin-top: -28.5px;
  background-color: white;
  display: block;
  position: absolute;
  left: calc(50% - 25px);
  font-weight: 600;
  width: 50px;
  text-align: center;
`;

const HRProduct = styled.p`
  margin-top: -28.5px;
  background-color: white;
  display: block;
  position: absolute;
  left: calc(50% - 60px);
  font-weight: 600;
  width: 120px;
  text-align: center;
`;

const Background = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const Status = styled.span`
  border-radius: 20px;
  padding: 0.3rem 0.8rem;
  font-size: 12px;
  margin-left: -15px;
`;
