import { Link } from "react-router-dom";
import { ArrowRight } from "react-bootstrap-icons";
import Order from "./Order";
import styled from "styled-components";
import { useSelector } from "react-redux";
import React from "react";

function Orders() {
  const orders = useSelector((state) => state.orders.orders);
  const variations = useSelector((state) => state.shop.variations);

  if (!variations || !orders) {
    return "laden";
  }

  const slidedOrders = orders.length > 3 ? orders.slice(0, 3) : orders;

  return (
    <Wrapper>
      <Inside>
        <p className="h5 pt-3 pb-3  ">Bestelgeschiedenis</p>
        <OrderWrapper>
          {orders &&
            slidedOrders.map((order) => (
              <Order order={order} key={order.id} variations={variations} />
            ))}
        </OrderWrapper>
      </Inside>
      <Link to="/orders" style={{ textDecoration: "none" }}>
        <AdjustOrder>
          Naar bestellingen
          <SVG>
            <ArrowRight />
          </SVG>
        </AdjustOrder>
      </Link>
    </Wrapper>
  );
}

export default Orders;

const Wrapper = styled.div`
  margin: 2rem 0 0 0;
  border: 0.0625rem solid #e8eef0;
  background-color: white;
  border-radius: 10px;
`;

const Inside = styled.div`
  margin: 1rem;
`;

const OrderWrapper = styled.div`
  border-top: 0.0625rem solid #e8eef0;

  & > div:not(:first-child) {
    border-top: 0.0625rem solid #e8eef0;
  }
`;

const AdjustOrder = styled.div`
  text-align: center;
  padding: 1rem;
  border-top: 0.0625rem solid #e8eef0;
  color: black;
  font-weight: 500;
`;

const SVG = styled.i`
  margin-left: 10px;
`;
