import axios from "axios";

export const searchAddress = async (url) => {
  return axios
    .get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch((err) => console.log(err));
};
