import React, { useState } from "react";
import style from "./newPassword.module.css";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { ReactComponent as Logo } from "../../assets/Udzwart.svg";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ResetPassword } from "../../components/queries/ResetPassword";
import { useSelector, useDispatch } from "react-redux";
import { CancelNewPw, postUser } from "../../redux/user/user.actions";

const NewPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.user.loginData) || null;

  const [email, setEmail] = useState(loginData?.username || null);

  const handleBack = () => {
    dispatch(CancelNewPw());

    history.push("/");
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (email === "") {
      toast.error("Je hebt geen email ingevuld!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else if (email.includes("@")) {
      const body = { email: email };

      ResetPassword(body);

      history.push("/nieuw-wachtwoord/succes");
    } else {
      toast.error("Je email is verkeerd.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div
          className="container bg-white pt-2 pb-2 mb-5 text-center"
          onClick={() => history.push("/")}
          role="button"
        >
          <Logo />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-10 col-md-6 bg-white p-5 ml-auto mr-auto">
              <p className="h3 text-center ">Nieuw wachtwoord</p>
              <p className="mt-3 text-center">
                In verband met het verhuizen van onze database moet je eenmalig
                je wachtwoord opnieuw instellen.
              </p>
              <form className="mt-4" onSubmit={sendEmail}>
                <span>E-mailadres</span>
                <input
                  className={style.input}
                  type="text"
                  defaultValue={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="email"
                />
                <br />
                <div className="text-center">
                  <Button
                    className="pr-3 pl-3 pt-2 mt-3 mr-3 pb-2"
                    onClick={() => handleBack()}
                  >
                    Terug
                  </Button>
                  <Button className="mt-3 pt-2 pb-2 pr-4 pl-4 " type="submit">
                    Doorgaan
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <StyledContainer />
      </div>
    </div>
  );
};

export default NewPassword;

const StyledContainer = styled(ToastContainer).attrs()`
  .Toastify__toast--error {
    background-color: #dc3545;
  }
  .Toastify__toast--success {
    background-color: #007bff;
  }
`;
