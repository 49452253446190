import React from "react";
import styled from "styled-components";
import {
  postSubscriptions,
  setActiveTabSubscription,
} from "../../../../../../../redux/subscriptions/subscriptions.actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

const Discount = ({ setQuit, setFinance }) => {
  const dispatch = useDispatch();

  const subscriptions = useSelector((state) => state.subscriptions);

  const handleQuit = () => {
    setFinance(false);
    setQuit(true);
  };

  const handleDiscount = () => {
    const body = {
      discount: "20%",
      discountMonths: 3,
    };

    dispatch(postSubscriptions(subscriptions.activeSubscription._id, body));
    dispatch(setActiveTabSubscription("Details"));
    setFinance(false);
  };

  return (
    <div className="text-center p-3">
      <h4>Laat ons je overtuigen!</h4>
      <p className="mt-3 m-auto" style={{ maxWidth: "400px" }}>
        Ontvang de komende 3 maanden 20% korting op je boxershorts om het
        uitgebreider te proberen.
      </p>
      <div className="text-center">
        {subscriptions.isLoading === false ? (
          <Button
            className="btn btn-primary mt-4 mb-3 checkout-button"
            onClick={() => handleDiscount()}
          >
            Yes, ik blijf!
          </Button>
        ) : (
          <Button className="btn btn-primary mt-4">
            <StyledSpinner animation="border" variant="white" />
          </Button>
        )}
        <div
          type="button"
          className="text-secondary mt-2"
          onClick={() => handleQuit()}
        >
          Nee, toch stoppen
        </div>
      </div>
    </div>
  );
};

export default Discount;

const StyledSpinner = styled(Spinner)`
  width: 20px;
  height: 20px;
`;
